import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  SafeAreaView,
  ScrollView,
  KeyboardAvoidingView,
  Platform
} from "react-native";
import Constants from "expo-constants";
import ConfettiCannon from "react-native-confetti-cannon";
import { StackActions } from "@react-navigation/native";
import LottieView from "src/components/Lottie";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Style from "src/globalStyles";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import Util from "src/utility";
import School from "school/school";
import Button from "src/components/Button";
import NavBar from "src/components/navBar";
import InputBox from "src/components/InputBox";
import Checkbox from "src/components/Checkbox";
import UserTypeButton from "src/components/UserTypeButton";
import HelpButton from "src/components/HelpButton";

const CREATED_APP_ANIMATION = require("resources/animations/appUnpacked.json");
const CREATING_APP_ANIMATION = require("resources/animations/codeMobileApp.json");

const { height, width } = Glob.get("dimensions");
const BYPASS_DESCRIPTION_WORD = "Nifty";
const DESCRIPTION_WORD_MINIMUM = 10;
const IS_ONESPOT = Constants.expoConfig.slug === "onespot";
const APP_CREATION_FORM = {
  id: "1FAIpQLScaK9Ne9lqkFjhrqAJQHxcK1Jaab608sLBVcSSck3l0NpYcVA",
  questions: [
    { text: "App Database ID", fieldID: 124919576 },
    { text: "App Name", fieldID: 1466138696 },
    { text: "App Type", fieldID: 1745667155 },
    { text: "App Description", fieldID: 989087614 },
    { text: "Main color", fieldID: 619667861 },
    { text: "Source", fieldID: 800416766 },
    { text: "Why want an app?", fieldID: 181925796 },
    { text: "Need custom branding?", fieldID: 759761086 },
    { text: "Website", fieldID: 418316966 },
    { text: "Platform", fieldID: 450509464 }
  ]
};

export default function OnespotCreateDetails({ navigation, route }) {
  const { params: { fullName } = {} } = route || {};
  const communityTypeID = Rex.getSessionMemory("newAppType");
  const portals = Rex.getSessionMemory("newAppPortals");
  const orgType = Glob.getCommunityType(communityTypeID);
  let orgTypePhrase = orgType.name.toLowerCase();
  if (orgTypePhrase === "other") orgTypePhrase = "community";
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [color, setColor] = useState(Glob.get("primaryColor"));
  const [selectedUserTypes, setSelectedUserTypes] = useState(
    new Set(orgType.userTypes || [])
  );
  const [source, setSource] = useState(
    !IS_ONESPOT || !!Rex.getLoginStatus() ? "[did not ask]" : ""
  );
  const [website, setWebsite] = useState(!IS_ONESPOT ? "[did not ask]" : "");
  const [wantStandaloneApp, setWantStandaloneApp] = useState(
    !IS_ONESPOT ? "[did not ask]" : null
  );
  const [isBuildingApp, setIsBuildingApp] = useState(false);
  const [builtAppID, setBuiltAppID] = useState(null);
  const [allAccountTypes, setAllAccountTypes] = useState(
    Glob.getAllAccountTypes().filter((type) => !type.hideOption)
  );
  const [visibleAccountTypes, setVisibleAccountTypes] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [finishedMessage, setFinishedMessage] = useState(
    "But it's not public yet. Join your app to add content and publish it."
  );

  useEffect(() => {
    const selectedTypes = new Set(orgType.userTypes || []);
    const accountTypesSelected = [];
    const accountTypesNotSelected = [];
    allAccountTypes.forEach((t) => {
      if (selectedTypes.has(t.key)) accountTypesSelected.push(t);
      else accountTypesNotSelected.push(t);
    });

    // alphabetize
    accountTypesSelected.sort((a, b) => a.buttonText > b.buttonText);
    accountTypesNotSelected.sort((a, b) => a.buttonText > b.buttonText);

    const combined = [...accountTypesSelected, ...accountTypesNotSelected];
    setAllAccountTypes(combined);
    setVisibleAccountTypes(combined.slice(0, 8));
    Analytics.logEvent("view_onespotCreateDetails");
  }, []);

  const UserTypeButtonWrapper = ({ type }) => {
    const { key } = type;
    return (
      <UserTypeButton
        key={`${key}-${selectedUserTypes.has(key)}`}
        backgroundColor="white"
        iconColor={color || Glob.get("primaryColor")}
        textColor="gray"
        type={key}
        selected={selectedUserTypes.has(key)}
        onPress={() => {
          const newSelectedUserTypes = new Set(selectedUserTypes);
          if (newSelectedUserTypes.has(key)) newSelectedUserTypes.delete(key);
          else newSelectedUserTypes.add(key);
          setSelectedUserTypes(newSelectedUserTypes);
        }}
      />
    );
  };

  // In the navigation stack, replace the onespotCreateType screen with onespotJoin
  const resetNavigationState = () => {
    const routeKeysToReplace = navigation
      .getState()
      .routes.filter((r) => r.name === "onespotCreateType");
    if (routeKeysToReplace.length > 0) {
      const routeKeyToReplace = routeKeysToReplace[0]?.key;
      navigation.dispatch({
        ...StackActions.replace("onespotJoin"),
        source: routeKeyToReplace,
        target: navigation.getState().key
      });
    }
  };

  const joinApp = () => {
    Analytics.logEvent("touch_onespotCreateDetails_joinCreatedApp", {
      appID: builtAppID
    });
    School.setDatabaseAppID(builtAppID);
    Database.fetchGlobalConfig().then((configData) => {
      Rex.setConfig(configData);
      navigation.replace("welcome", { fullName });
    });
  };

  const buildApp = async () => {
    setIsBuildingApp(true);
    const trimmedName = (name || "").trim();
    const trimmedDescription = (description || "").trim();
    const app = {
      name: trimmedName,
      description: trimmedDescription,
      communityType: communityTypeID,
      color: color || Glob.get("primaryColor"),
      portals,
      source,
      userAccountTypes: [...selectedUserTypes].map((typeID) => {
        const type = Glob.getAccountType(typeID);
        return { key: typeID, title: type.buttonText, icon: type.iconName };
      }),
      extraMetadata: {
        ...(website && website !== "[did not ask]" ? { website } : {}),
        ...(wantStandaloneApp && wantStandaloneApp !== "[did not ask]"
          ? { wantStandaloneApp: wantStandaloneApp === "yes" }
          : {})
      }
    };
    Analytics.logEvent("touch_onespotCreateDetails_buildApp", app);
    const messages = await Database.generateAppCreationLoadingMessageFromDescription(
      trimmedName,
      trimmedDescription,
      orgTypePhrase
    );
    if (messages?.success) {
      setLoadingMessage(messages?.loadingMessage || "");
      setFinishedMessage(
        messages?.finishedMessage ||
          "But it's not public yet. Join your app to add content and publish it."
      );
    }
    Database.onespotCreateNewApp(app).then(({ appID = null }) => {
      Analytics.logEvent("action_onespotCreateDetails_buildAppComplete", {
        appID
      });
      Util.localStorageGetItemAsync("createdAppIDs").then((ids) => {
        const prevCreatedAppIDs = JSON.parse(ids) || [];
        const newCreatedAppIDs = [...prevCreatedAppIDs, appID];
        Util.localStorageSetItemAsync(
          "createdAppIDs",
          JSON.stringify(newCreatedAppIDs)
        );
        resetNavigationState();
        Database.fetchMetaApp().then((metaApp) => {
          Rex.setMetaApp(metaApp);
          setName(trimmedName);
          setBuiltAppID(appID);
          try {
            let standaloneAppAnswer = `${wantStandaloneApp}`;
            if (wantStandaloneApp === "yes")
              standaloneAppAnswer =
                "Yes, my app and logo need to be listed on the iOS and Android app stores";
            else if (wantStandaloneApp === "no")
              standaloneAppAnswer =
                "No, my app doesn't need to have its own app store listing as long as it solves my problem";
            Util.fillDynamicForm(APP_CREATION_FORM, [
              appID,
              trimmedName,
              communityTypeID || "",
              trimmedDescription,
              color || Glob.get("primaryColor") || "",
              source || "",
              "[did not ask]", // Why want an app?
              standaloneAppAnswer || "",
              website || "",
              Platform.OS || ""
            ]);
          } catch (error) {
            console.log("Error");
            console.log(error);
          }
        });
      });
    });
  };

  if (builtAppID)
    return (
      <SafeAreaView
        style={{
          height,
          width: "100%",
          alignItems: "center",
          backgroundColor: "white"
        }}
      >
        <View style={{ height, width }}>
          <View
            style={{
              flex: 6,
              justifyContent: "center",
              alignItems: "center",
              marginVertical: "10%"
            }}
          >
            <LottieView
              style={{ height: "100%", width: "100%" }}
              autoPlay
              loop={false}
              source={CREATED_APP_ANIMATION}
            />
          </View>
          <View
            style={{
              flex: 3,
              justifyContent: "center",
              marginHorizontal: 20
            }}
          >
            <Text
              style={{
                fontSize: 24,
                color: Glob.get("primaryColor"),
                fontWeight: "bold"
              }}
            >
              Your app is ready 🎉
            </Text>
            <Text style={{ color: "#888", marginTop: 20 }}>
              {finishedMessage}
            </Text>
            <Text style={{ color: "#888", marginTop: 20 }}>
              You can quit Onespot at any time and your work will be saved. And
              you can always find your app from within "Join an App".
            </Text>
          </View>
          <View
            style={{
              flex: 4,
              justifyContent: "center",
              alignItems: "center",
              marginHorizontal: 20
            }}
          >
            <Button
              text="Join!"
              onPress={joinApp}
              textStyle={[
                styles.buttonText,
                { fontSize: 32, color: Glob.get("primaryColor") }
              ]}
              style={[styles.button, { height: 80, backgroundColor: "white" }]}
            />
          </View>
        </View>
        <ConfettiCannon
          count={200}
          origin={{ x: width / 2, y: height / 1.5 }}
          autoStart
        />
      </SafeAreaView>
    );

  if (isBuildingApp)
    return (
      <SafeAreaView
        style={{
          height,
          width: "100%",
          alignItems: "center",
          backgroundColor: "white"
        }}
      >
        <View style={{ height: "100%", width }}>
          <View
            style={{
              flex: 6,
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <LottieView
              style={styles.loadingImage}
              autoPlay
              loop
              source={CREATING_APP_ANIMATION}
            />
          </View>
          <View
            style={{
              flex: 3,
              justifyContent: "center",
              marginHorizontal: 20
            }}
          >
            <Text
              style={{
                fontSize: 24,
                color: Glob.get("primaryColor"),
                fontWeight: "bold",
                textAlign: Glob.deviceIsTablet() ? "center" : "left"
              }}
            >
              Building your app now...
            </Text>
            <Text
              style={{
                color: "#888",
                marginTop: 10,
                textAlign: Glob.deviceIsTablet() ? "center" : "left"
              }}
            >
              {loadingMessage}
            </Text>
          </View>
        </View>
      </SafeAreaView>
    );

  const allAccountButtonRows = [];
  let temporaryRow = [];
  visibleAccountTypes.forEach((type, idx) => {
    if (idx % 4 === 3) {
      temporaryRow.push(<UserTypeButtonWrapper type={type} />);
      allAccountButtonRows.push(
        <View style={styles.buttonRowContainer}>{temporaryRow}</View>
      );
      temporaryRow = [];
    } else {
      temporaryRow.push(<UserTypeButtonWrapper type={type} />);
    }
  });
  if (temporaryRow.length > 0) {
    allAccountButtonRows.push(
      <View style={styles.buttonRowContainer}>{temporaryRow}</View>
    );
  }

  const showingAllAccountTypes = visibleAccountTypes.length > 8;
  const descriptionWordCount = !description
    ? 0
    : description.trim().split(" ").length;
  const descriptionWordCountTooShort =
    descriptionWordCount < DESCRIPTION_WORD_MINIMUM &&
    description !== BYPASS_DESCRIPTION_WORD;

  const canContinue =
    selectedUserTypes?.size > 0 &&
    selectedUserTypes?.size < 9 &&
    name?.length > 0 &&
    source?.length > 0 &&
    !descriptionWordCountTooShort &&
    !!wantStandaloneApp;
  const websiteDomainPlaceholder = `${name || `my${orgTypePhrase}`}.com`
    .replace(/ /g, "")
    .replace(/,/g, "")
    .replace(/\//g, "")
    .toLowerCase();
  return (
    <KeyboardAvoidingView
      style={styles.mainView}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar
        isOnespot={!name}
        navigation={navigation}
        text={name}
        backgroundColor={color}
        RightButton={
          <View style={{ alignItems: "flex-end" }}>
            <HelpButton
              title="Creating an App"
              message="You're almost done! After this screen, the basic structure of your app will be finished."
              navigation={navigation}
              videoUrl="https://youtu.be/s9m6JUfsTlY"
            />
          </View>
        }
      />
      <ScrollView
        contentContainerStyle={{ paddingHorizontal: 15, width }}
        scrollIndicatorInsets={{ right: 1 }}
        keyboardDismissMode="on-drag"
      >
        <InputBox
          key="title"
          header={`What's the name of your ${orgTypePhrase}?`}
          onChangeText={setName}
          placeholder={orgType.example}
          value={name}
          autoCapitalize="words"
          headerColorOverride={color || Glob.get("primaryColor")}
        />
        <InputBox
          key="title"
          header="Describe your app"
          description="Write as much detail as possible. Onespot AI will use this description to make your app's first screens."
          onChangeText={setDescription}
          placeholder={`${name ||
            orgType.example} is a wonderful ${orgTypePhrase} based in San Francisco, California. This app for ${name ||
            orgType.example} will include screens for About Us, Contact Us, and Community Directory. Our contact email is info@${websiteDomainPlaceholder}.`}
          value={description}
          multiline
          headerColorOverride={color || Glob.get("primaryColor")}
          minHeight={100}
          isInvalid={!!description && descriptionWordCountTooShort}
        />
        {!!description && (
          <>
            {descriptionWordCountTooShort ? (
              <Text
                style={{
                  color: "#F03738"
                }}
              >
                {descriptionWordCount} word
                {descriptionWordCount === 1 ? "" : "s"} {"(please write more)"}
              </Text>
            ) : (
              <>
                {descriptionWordCount < 30 ? (
                  <Text
                    style={{
                      color: "#2DD881"
                    }}
                  >
                    The more you write, the better your app will be.
                  </Text>
                ) : (
                  <Text
                    style={{
                      color: "#2DD881"
                    }}
                  >
                    Nice description 👍 Keep going if you want!
                  </Text>
                )}
              </>
            )}
          </>
        )}
        <InputBox
          key="color"
          header="Pick the main color for your app"
          description="Don't worry; you can always change this later."
          onChangeText={setColor}
          placeholder="Optional"
          value={color}
          colorPicker
          headerColorOverride={color || Glob.get("primaryColor")}
        />
        <View style={{ width: "100%" }}>
          <Text
            style={[
              Style.get("headerText"),
              { color: color || Glob.get("primaryColor"), marginTop: 20 }
            ]}
          >
            What types of users will your app support?
          </Text>
          <Text>You can fully customize these account types later.</Text>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            width: "95%"
          }}
        >
          {allAccountButtonRows}
          {!showingAllAccountTypes && (
            <Button
              small
              text="Show all user types"
              onPress={() => setVisibleAccountTypes(allAccountTypes)}
              style={{ backgroundColor: color }}
            />
          )}
          {selectedUserTypes?.size > 8 && (
            <Text style={[styles.questionText, { color: "red" }]}>
              You can only select up to 8 account types
            </Text>
          )}
        </View>
        {IS_ONESPOT && (
          <InputBox
            key="website"
            header={`What's the website of your ${orgTypePhrase}?`}
            description="(Optional)"
            placeholder={`www.${websiteDomainPlaceholder}`}
            onChangeText={setWebsite}
            value={website}
            keyboardType="url"
            headerColorOverride={color || Glob.get("primaryColor")}
          />
        )}
        {IS_ONESPOT && !Rex.getLoginStatus() && (
          <InputBox
            key="source"
            header="How'd you discover Onespot?"
            onChangeText={setSource}
            placeholder="Required"
            value={source}
            headerColorOverride={color || Glob.get("primaryColor")}
          />
        )}
        {IS_ONESPOT && (
          <>
            <Text
              style={[
                Style.get("headerText"),
                {
                  color: color || Glob.get("primaryColor"),
                  marginTop: 20,
                  marginBottom: 3
                }
              ]}
            >
              Does your app need to have custom branding?
            </Text>
            <Checkbox
              checked={wantStandaloneApp === "yes"}
              radio
              text={
                <Text>
                  <Text style={{ fontWeight: "bold" }}>Yes</Text>, my app & logo
                  need to be listed on the iOS & Android app stores
                </Text>
              }
              onChange={() => setWantStandaloneApp("yes")}
            />
            <Checkbox
              checked={wantStandaloneApp === "no"}
              radio
              text={
                <Text>
                  <Text style={{ fontWeight: "bold" }}>No</Text>, my app doesn't
                  need to have its own app store listing as long as it solves my
                  problem
                </Text>
              }
              onChange={() => setWantStandaloneApp("no")}
            />
          </>
        )}
        <Button
          disabled={!canContinue}
          text="Build it!"
          onPress={buildApp}
          textStyle={styles.buttonText}
          style={[
            styles.button,
            { opacity: canContinue ? 1 : 0.3, backgroundColor: color }
          ]}
        />
        <View style={{ height: 100 }} />
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const button = {
  backgroundColor: Glob.get("primaryColor"),
  marginVertical: 30,
  shadowOpacity: 0.3,
  shadowOffset: { width: 0, height: 0 },
  shadowRadius: 5,
  elevation: 10
};
if (Platform.OS === "web") button.boxShadow = "0px 0px 5px rgba(0,0,0,0.3)";

const styles = StyleSheet.create({
  questionText: {
    fontSize: 16,
    marginTop: 30
  },

  mainView: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },

  loadingImage: {
    resizeMode: "contain",
    height: "100%",
    width: "100%"
  },

  buttonRowContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    marginVertical: 10
  },

  button,

  buttonText: {
    color: "white"
  }
});

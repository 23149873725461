import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform
} from "react-native";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Icon from "src/components/Icon";

const { width } = Glob.get("dimensions");

// type can be 'student', 'alum', 'parent', 'coach', 'faculty', 'other', etc.
export default function UserTypeButton({
  type,
  title,
  icon,
  onPress,
  selected,
  outlineSelection,
  backgroundColor,
  iconColor,
  textColor
}) {
  let buttonTitle;
  let buttonIcon;
  if (title && icon) {
    buttonTitle = title;
    buttonIcon = icon;
  } else {
    const accountType = Glob.getAccountType(type);
    const { buttonText, iconName } = accountType;
    buttonTitle = buttonText;
    buttonIcon = iconName;
  }
  const iconSize = Glob.deviceIsTablet() ? 0.04 * width : 0.071 * width;
  const raisedStyle = {
    opacity: 1,
    shadowOpacity: 0.3,
    shadowRadius: 7,
    shadowOffset: { width: 0, height: 0 },
    elevation: 10
  };
  const outlinedStyle = {
    ...raisedStyle,
    borderWidth: 3,
    borderColor: iconColor || Rex.getConfig()?.colors?.background
  };
  if (Platform.OS === "web")
    raisedStyle.boxShadow = "0px 0px 7px rgba(0,0,0,0.3)";
  const selectedStyle = outlineSelection ? outlinedStyle : raisedStyle;
  const unselectedStyle = outlineSelection ? raisedStyle : {};
  const buttonStyles = [
    styles.userTypeButton,
    selected ? selectedStyle : unselectedStyle,
    { backgroundColor: backgroundColor || Rex.getConfig()?.colors?.portal }
  ];
  return (
    <View key={type} style={styles.container}>
      <TouchableOpacity activeOpacity={0.7} onPress={onPress}>
        <View style={{ flexDirection: "column", alignItems: "center" }}>
          <View style={buttonStyles}>
            <Icon
              icon={buttonIcon}
              color={iconColor || Rex.getConfig()?.colors?.background}
              size={iconSize}
            />
          </View>
          <Text style={[styles.text, { color: textColor || "white" }]}>
            {buttonTitle}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: Glob.get("widthOfUserTypeButton"),
    padding: 3
  },
  userTypeButton: {
    height: Glob.get("widthOfUserTypeButton") - 35,
    width: Glob.get("widthOfUserTypeButton") - 35,
    marginLeft: 0.027 * width,
    marginRight: 0.027 * width,
    marginBottom: 3,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 0.179 * width,
    opacity: 0.3
  },
  text: {
    textAlign: "center",
    fontWeight: "400"
  }
});

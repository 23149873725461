import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  Animated,
  ActivityIndicator,
  Alert
} from "react-native";
import {
  MaterialCommunityIcons,
  FontAwesome5,
  MaterialIcons,
  Octicons
} from "@expo/vector-icons";
import * as Haptics from "expo-haptics";
import Rex from "src/globalState";
import Style from "src/globalStyles";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import Util from "src/utility";
import MiniScreen from "src/screens/admin/miniScreen";
import NavBar from "src/components/navBar";
import TileStatic from "src/components/TileStatic";
import Button from "src/components/Button";
import InputBox from "src/components/InputBox";
import AlertModal from "src/components/AlertModal";
import IconPicker from "src/components/IconPicker";
import Checkbox from "src/components/Checkbox";
import Multiselect from "src/components/Multiselect";
import HelpText from "src/components/HelpText";
import TouchableLink from "src/components/dynamicContent/TouchableLink";
import StatusMessage from "src/components/StatusMessage";
import OnespotProBadge from "src/components/OnespotProBadge";
import DocumentPicker from "src/components/DocumentPicker";
import UserList from "src/components/UserList";
import BottomSheetModal from "src/components/BottomSheetModal";

const { height, width } = Glob.get("dimensions");
const INTRO_HEADER = "INTRODUCTION_HEADER_TEXT_KEY";
const TEMPLATES_HEADER = "TEMPLATES_HEADER_TEXT_KEY";
const TEMPLATES_BUTTONS = "TEMPLATES_HEADER_BUTTONS_KEY";
const OTHER_HEADER = "OTHER_HEADER_TEXT_KEY";

const PORTAL_TYPE_OPTIONS = [
  INTRO_HEADER,
  "dynamic",
  "webNav",
  "events",
  "pdfDocument",
  "activityFeedChatStyle", // only if activity feeds are enabled (otherwise filtered out)
  "activityFeedFeedStyle", // only if activity feeds are enabled (otherwise filtered out)
  TEMPLATES_HEADER,
  TEMPLATES_BUTTONS,
  // "dynamicForm",
  OTHER_HEADER,
  "native"
];

const MODULE_FONT_SIZE = 0.027 * height;
const MODULE_TEXT_PADDING = 0;
const TILE_WIDTH = Glob.deviceIsTablet() ? 0.8 * 0.303 * width : 0.303 * width;
// let TILE_WIDTH = 0.1515*width;
const TILE_HEIGHT = Glob.deviceIsiPhoneX()
  ? 0.8 * 0.167 * height
  : 0.167 * height;
// let TILE_HEIGHT = ((Glob.deviceIsiPhoneX()) ? 0.8*0.0835*height : 0.0835*height);

const MESSAGE_CREATED_NEW_PORTAL =
  "The new screen has been created (and the portal has been added to your home screen).";
const MESSAGE_UPDATED_EXISTING_PORTAL = "The screen has been updated.";

export default function EditPortal({ route, navigation }) {
  const metadataStructure = {
    txtName: "Screen",
    imgName: "web", // backward-compatible
    icon: "web",
    portalType: "webNav"
  };
  const contentStructure = { title: "Screen", backgroundColor: "#ffffff" };
  const { params: { portalMetadata, onUpdatePortal } = {} } = route || {};
  const originalMetadata = portalMetadata
    ? { ...portalMetadata, icon: portalMetadata.icon || portalMetadata.imgName }
    : null; // backward-compatible
  const [isNew, setIsNew] = useState(!originalMetadata);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [metadataOfNewPortal, setMetadataOfNewPortal] = useState(null);
  const [templatePortals, setTemplatePortals] = useState(null);
  const [communityType, setCommunityType] = useState("community");
  const [isAddingPortalForEveryone, setIsAddingPortalForEveryone] = useState(
    false
  );
  const [
    positionToAddPortalForEveryone,
    setPositionToAddPortalForEveryone
  ] = useState(null);
  const [didAddPortalForEveryone, setDidAddPortalForEveryone] = useState(false);
  const [didPreviewNotification, setDidPreviewNotification] = useState(false);
  const [didSendNotification, setDidSendNotification] = useState(false);
  const [hasEditedDynamicContent, setHasEditedDynamicContent] = useState(false);
  const [
    hasEditedAllowedAccountTypes,
    setHasEditedAllowedAccountTypes
  ] = useState(false);
  const [
    hasEditedAccountTypesAllowedToPost,
    setHasEditedAccountTypesAllowedToPost
  ] = useState(false);
  const [
    hasEditedAccountTypesAllowedToComment,
    setHasEditedAccountTypesAllowedToComment
  ] = useState(false);
  const [metadata, setMetadata] = useState(
    originalMetadata || metadataStructure
  );
  const [content, setContent] = useState(isNew ? contentStructure : null);
  const [originalContent, setOriginalContent] = useState({});
  const [fetchingContent, setFetchingContent] = useState(!isNew);
  const [allAccountTypes, setAllAccountTypes] = useState(null);
  const [isSelectingPortalType, setIsSelectingPortalType] = useState(isNew);
  const [miniScreenKey, setMiniScreenKey] = useState("miniscreen"); // Used to force updates after editing dynamic content
  // const [scrollY, setScrollY] = useState(null);
  const [contentIsExpanded, setContentIsExpanded] = useState(true);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [allowedToSendNotifications, setAllowedToSendNotifications] = useState(
    false
  );
  const [allowedToManageUsers, setAllowedToManageUsers] = useState(false);
  const [allowedToEditAllPortals, setAllowedToEditAllPortals] = useState(false);
  const [createdPortalID, setCreatedPortalID] = useState(null);
  const [showMorePermissions, setShowMorePermissions] = useState(false);
  const [portalEditors, setPortalEditors] = useState(null);
  const [isSettingEditors, setIsSettingEditors] = useState(false);
  const [portalEditorChangesSaved, setPortalEditorChangesSaved] = useState(
    false
  );
  const [canSave, setCanSave] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    const valuesChanged =
      metadata.txtName !== originalMetadata?.txtName ||
      metadata.icon !== originalMetadata?.icon ||
      content?.title !== originalContent?.title ||
      content?.url !== originalContent?.url ||
      content?.backgroundColor !== originalContent?.backgroundColor ||
      content?.googleCalendarID !== originalContent?.googleCalendarID ||
      content?.splitUpMultiDayEvents !==
        originalContent?.splitUpMultiDayEvents ||
      content?.hoursOffset !== originalContent?.hoursOffset ||
      content?.iCalURL !== originalContent?.iCalURL ||
      content?.googleSheetID !== originalContent?.googleSheetID ||
      content?.feedType !== originalContent?.feedType ||
      content?.feedTopic !== originalContent?.feedTopic ||
      content?.disableLikes !== originalContent?.disableLikes ||
      content?.disableComments !== originalContent?.disableComments ||
      content?.activityFeed !== originalContent?.activityFeed;
    if (metadata.portalType === "dynamic" && isNew)
      setCanSave(hasEditedDynamicContent);
    else if (metadata.portalType === "events" && isNew)
      setCanSave(!!content?.googleCalendarID || content?.iCalURL);
    else if (metadata.portalType === "pdfDocument" && !content?.url)
      setCanSave(false);
    else
      setCanSave(
        valuesChanged ||
          hasEditedDynamicContent ||
          hasEditedAllowedAccountTypes ||
          hasEditedAccountTypesAllowedToPost ||
          hasEditedAccountTypesAllowedToComment
      );
  }, [
    metadata,
    content,
    originalContent,
    hasEditedDynamicContent,
    hasEditedAllowedAccountTypes,
    hasEditedAccountTypesAllowedToPost,
    hasEditedAccountTypesAllowedToComment
  ]);

  const metadataRef = useRef();
  const contentRef = useRef();
  const canSaveRef = useRef();
  const isNewRef = useRef();
  const isSubmittedRef = useRef();
  const isSelectingPortalTypeRef = useRef();
  useEffect(() => {
    metadataRef.current = metadata;
  }, [metadata]);
  useEffect(() => {
    contentRef.current = content;
  }, [content]);
  useEffect(() => {
    canSaveRef.current = canSave;
  }, [canSave]);
  useEffect(() => {
    isNewRef.current = isNew;
  }, [isNew]);
  useEffect(() => {
    isSubmittedRef.current = isSubmitted;
  }, [isSubmitted]);
  useEffect(() => {
    isSelectingPortalTypeRef.current = isSelectingPortalType;
  }, [isSelectingPortalType]);

  useEffect(() => {
    Database.fetchUserPrivileges(false).then((privileges) => {
      setAllowedToManageUsers(privileges?.ManageUsers);
      setAllowedToEditAllPortals(privileges?.EditAllPortals);
      // Only if user is allowed to send notifications to EVERYONE
      setAllowedToSendNotifications(privileges?.PushNotifications === true);
    });
    if (isNew) {
      Database.fetchAppCommunityType().then((type) => {
        setCommunityType(type);
        Database.fetchAllTemplatePortals(type?.key).then(setTemplatePortals);
      });
    }
    Database.fetchAllAccountTypeDetails().then((details) => {
      if (details) setAllAccountTypes(details);
      else {
        // Handle legacy database structure
        Database.getAllDefaultPortals((portals) => {
          const newAllAccountTypes = {};
          if (portals)
            Object.keys(portals).forEach((type) => {
              newAllAccountTypes[type] = true;
            });
          setAllAccountTypes(newAllAccountTypes);
        });
      }
    });

    if (!isNew) {
      Database.getPortalContentNew(metadata.navName).then(
        (rawPortalContent) => {
          const portalContent = rawPortalContent;
          if (rawPortalContent && !rawPortalContent?.backgroundColor)
            portalContent.backgroundColor = "#ffffff";
          Analytics.logEvent("view_editPortal", {
            key: metadata.navName,
            title: metadata.txtName,
            isNew
          });
          setOriginalContent(portalContent);
          setContent(portalContent);
          setFetchingContent(false);
        }
      );
      Database.subscribeToPortalEditors(metadata.navName, setPortalEditors);
    } else {
      Analytics.logEvent("view_editPortal", { isNew });
    }

    // When user goes back, check if they meant to save their work
    return () => {
      if (
        canSaveRef.current &&
        !isSubmittedRef.current &&
        !isSelectingPortalTypeRef.current
      ) {
        const portalWasCreatedMessage = isNewRef.current
          ? MESSAGE_CREATED_NEW_PORTAL
          : MESSAGE_UPDATED_EXISTING_PORTAL;
        Alert.alert(
          "Just checking...",
          `Did you mean to save ${
            isNew ? "that new screen" : "those edits"
          } you just made?`,
          [
            { text: "💥 Discard", onPress: () => {}, style: "destructive" },
            {
              text: "💾 Save",
              onPress: () => {
                onSubmitResponse(metadataRef.current, contentRef.current);
                Alert.alert("Changes saved 👍", portalWasCreatedMessage);
              },
              style: "cancel"
            }
          ]
        );
      }
      return Database.unsubscribeFromPortalEditors(metadata.navName);
    };
  }, []);

  const onSubmitResponse = (newMetadata, newContent) => {
    const newContentClean = Util.cleanPortalContent(newContent);
    if (Platform.OS !== "web")
      Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
    if (isNew) {
      Analytics.logEvent("touch_editPortal_create", {
        key: newMetadata.navName,
        title: newMetadata.txtName
      });
      Database.addPortal(newMetadata, newContentClean).then(
        (fetchedMetadata) => {
          const oldPortals = Rex.getHomePortals();
          const newPortals = [...oldPortals, fetchedMetadata];
          const portalKeys = newPortals.map((portal) => portal.navName);
          if (portalKeys.length > 0) Rex.orderChanged(newPortals);
          onUpdatePortal();
          setMetadataOfNewPortal(fetchedMetadata);
          const { navName: newNavName } = fetchedMetadata;
          setCreatedPortalID(newNavName);
        }
      );
      Database.addTask("createNewPortal", "done");
    } else {
      Analytics.logEvent("touch_editPortal_saveChanges", {
        key: newMetadata.navName,
        title: newMetadata.txtName
      });
      Database.updatePortal(newMetadata, newContentClean).then(() =>
        onUpdatePortal()
      );
      Database.addTask("editPortal", "done");
    }
    setIsSubmitted(true);
  };

  const onPressDelete = () => {
    setAlert({
      title: "Just checking...",
      message: "Are you sure you want to delete this portal?",
      confirm: { text: "✋ Cancel" },
      cancel: { text: "💥 Delete", onPress: deletePortal }
    });
  };

  const onPressEditContent = () => {
    if (metadata.portalType === "dynamic") {
      Analytics.logEvent("touch_editPortal_editDynamicContent", {
        key: metadata.navName,
        title: metadata.txtName,
        isNew
      });
      setHasEditedDynamicContent(true);
      navigation.push("dynamic", {
        portalOverride: {
          updateContent: (newContent) => {
            setMiniScreenKey(Math.random());
            setContent(newContent);
          },
          ...content
        }
      });
    }
  };

  const deletePortal = () => {
    Analytics.logEvent("touch_editPortal_delete", {
      key: metadata.navName,
      title: metadata.txtName
    });
    Database.deletePortal(metadata.navName);
    onUpdatePortal();
    navigation.goBack();
  };

  const duplicatePortal = () => {
    Analytics.logEvent("touch_editPortal_duplicate", {
      key: metadata.navName,
      title: metadata.txtName
    });
    const newTitle = `Copy of ${metadata.txtName}`;
    setMetadata({ ...metadata, txtName: newTitle });
    setContent({ ...content, title: newTitle });
    setIsNew(true);
  };

  const addPortalForEveryone = (index) => {
    const portalData = metadataOfNewPortal || metadata;
    Analytics.logEvent("touch_editPortal_addPortalForEveryone", {
      key: portalData.navName,
      title: portalData.txtName,
      index
    });
    Database.addPortalForAllUsers(portalData.navName, index);
    setDidAddPortalForEveryone(true);
  };

  const sendNotification = (notification) => {
    Analytics.logEvent("touch_editPortal_sendNotification", notification);
    Database.addTask("sendPushNotification", "done");
    Database.sendPushNotification(notification);
    setDidSendNotification(true);
  };

  const explainPortalType = (type = metadata.portalType) => {
    const { name, explanation } = Util.portalTypeToDetails(type);
    setAlert({ title: `${name} Screen`, message: explanation });
  };

  const allowAllAccountTypes = () => {
    setHasEditedAllowedAccountTypes(true);
    setMetadata({
      ...metadata,
      allowedAccountTypes: null,
      restrictedToFeedSubscribers: null
    });
  };

  const allowAllAccountTypesIndividually = () => {
    const accountTypeKeys = Object.keys(allAccountTypes);
    setHasEditedAllowedAccountTypes(true);
    // Convert accountTypeKeys to an object with all values set to true
    const newAllowedAccountTypes = accountTypeKeys.reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {});
    setMetadata({
      ...metadata,
      allowedAccountTypes: newAllowedAccountTypes,
      restrictedToFeedSubscribers: null
    });
  };

  const allowNoAccountTypes = () => {
    setHasEditedAllowedAccountTypes(true);
    const newAllowedAccountTypes = {
      [Glob.get("hiddenScreenAccountTypeKey")]: true
    };
    setMetadata({
      ...metadata,
      allowedAccountTypes: newAllowedAccountTypes,
      restrictedToFeedSubscribers: null
    });
  };

  const restrictFeedToSubscribers = () => {
    setHasEditedAllowedAccountTypes(true);
    setMetadata({
      ...metadata,
      allowedAccountTypes: null,
      restrictedToFeedSubscribers: true
    });
  };

  const onToggleAllowedAccountType = (type, active) => {
    setHasEditedAllowedAccountTypes(true);
    const newAllowedAccountTypes = metadata.allowedAccountTypes || {};
    newAllowedAccountTypes[type] = active;
    setMetadata({
      ...metadata,
      allowedAccountTypes: newAllowedAccountTypes,
      restrictedToFeedSubscribers: null
    });
  };

  const allowAllAccountTypesToPost = () => {
    setHasEditedAccountTypesAllowedToPost(true);
    setShowMorePermissions(true); // Ensure we don't hide the checkboxes
    setContent({ ...content, accountTypesAllowedToPost: null });
  };

  const allowAllAccountTypesToPostIndividually = () => {
    const accountTypeKeys = Object.keys(allAccountTypes);
    setHasEditedAccountTypesAllowedToPost(true);
    // Convert accountTypeKeys to an object with all values set to true
    const newAccountTypes = accountTypeKeys.reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {});
    setContent({
      ...content,
      accountTypesAllowedToPost: newAccountTypes
    });
  };

  const onToggleAccountTypeAllowedToPost = (type, active) => {
    setHasEditedAccountTypesAllowedToPost(true);
    const newAccountTypes = content?.accountTypesAllowedToPost || {};
    newAccountTypes[type] = active;
    setContent({ ...content, accountTypesAllowedToPost: newAccountTypes });
  };

  const allowAllAccountTypesToComment = () => {
    setHasEditedAccountTypesAllowedToComment(true);
    setShowMorePermissions(true); // Ensure we don't hide the checkboxes
    setContent({ ...content, accountTypesAllowedToComment: null });
  };

  const allowAllAccountTypesToCommentIndividually = () => {
    const accountTypeKeys = Object.keys(allAccountTypes);
    setHasEditedAccountTypesAllowedToComment(true);
    // Convert accountTypeKeys to an object with all values set to true
    const newAccountTypes = accountTypeKeys.reduce((acc, key) => {
      acc[key] = true;
      return acc;
    }, {});
    setContent({
      ...content,
      accountTypesAllowedToComment: newAccountTypes
    });
  };

  const onToggleAccountTypeAllowedToComment = (type, active) => {
    setHasEditedAccountTypesAllowedToComment(true);
    const newAccountTypes = content?.accountTypesAllowedToComment || {};
    newAccountTypes[type] = active;
    setContent({ ...content, accountTypesAllowedToComment: newAccountTypes });
  };

  const onToggleUserEditor = (userID) => {
    const shouldBeEditor = !(portalEditors || {})[userID];
    Analytics.logEvent(
      `touch_editPortal_${shouldBeEditor ? "add" : "remove"}PortalEditor`,
      { portalID: metadata.navName, user: userID }
    );
    Database.setPortalEditor(metadata.navName, userID, shouldBeEditor || null);
    setPortalEditorChangesSaved(true);
  };

  // const handleScroll = (event) => {
  //   // console.log('event');
  //   // console.log(event);
  //   setScrollY(event.nativeEvent.contentOffset.y);
  // }

  const onSelectPortalType = (portalTypeDetails, isTemplate) => {
    const { type, name, icon, templateContent = null } = portalTypeDetails;
    Analytics.logEvent("touch_editPortal_selectNewPortalType", {
      name,
      type,
      hasTemplateContent: !!templateContent
    });

    if (isTemplate && templateContent) {
      setMetadata({ ...metadata, portalType: type, icon, txtName: name });
      setContent({
        ...content,
        ...templateContent,
        title: name
      });
      setIsSelectingPortalType(false);
    } else if (type === "webNav") {
      setMetadata({ ...metadata, portalType: type, icon });
      setContent({ ...content, url: "https://www.google.com" });
      setIsSelectingPortalType(false);
    } else if (type === "dynamic") {
      setMetadata({ ...metadata, portalType: type, icon: "academics" });
      setContent({ ...content, content: [] });
      setIsSelectingPortalType(false);
    } else if (type === "events") {
      setMetadata({
        ...metadata,
        txtName: "Events",
        portalType: type,
        icon
      });
      setContent({ ...content, title: "Events" });
      setShowAdvancedSettings(true);
      setIsSelectingPortalType(false);
    } else if (type === "pdfDocument") {
      if (Platform.OS === "web") {
        setMetadata({
          ...metadata,
          txtName: "PDF",
          portalType: type,
          icon
        });
        setContent({ ...content, title: "PDF" });
        setIsSelectingPortalType(false);
      } else {
        Util.alert(
          "Upload from Computer",
          "New PDF files can only be uploaded from your computer.\n\nTo open your app on your computer, open the web version in your phone's browser. Then, copy the page link and send it to your computer so you can log in from there. Make sure to bookmark the page for easy access!",
          [
            {
              text: "Cancel",
              onPress: () => {},
              style: "cancel"
            },
            {
              text: "Open in Browser",
              onPress: () => {
                Database.fetchPrimaryMetaApp().then((metaApp) => {
                  if (metaApp?.webLink) Util.openURL(metaApp.webLink);
                });
              }
            }
          ]
        );
      }
    } else if (
      type === "activityFeedFeedStyle" ||
      type === "activityFeedChatStyle"
    ) {
      const feedType = type === "activityFeedChatStyle" ? "chat" : "feed";
      setMetadata({
        ...metadata,
        txtName: name,
        portalType: "activityFeed",
        icon,
        ...(feedType === "chat" ? { restrictedToFeedSubscribers: true } : {})
      });
      setContent({
        ...content,
        title: name,
        backgroundColor: "#f4f2ee",
        feedType,
        activityFeed: Database.generateUniqueID()
      });
      setIsSelectingPortalType(false);
    } else {
      navigation.push("dynamicForm", {
        navName: "RequestCustomPortal",
        isGlobalForm: true
      });
    }
  };

  if (isSelectingPortalType) {
    return (
      <View style={styles.pageContent}>
        <NavBar navigation={navigation} text="New Screen" />
        <ScrollView
          contentContainerStyle={{ alignItems: "center", width }}
          style={{ flex: 1 }}
          scrollIndicatorInsets={{ right: 1 }}
        >
          {PORTAL_TYPE_OPTIONS.map((type) => {
            if (type === INTRO_HEADER) {
              return (
                <View style={{ margin: 20, marginBottom: 0, width: "100%" }}>
                  <Text
                    style={[Style.get("headerText"), { marginHorizontal: 20 }]}
                  >
                    What kind of screen do you want?
                  </Text>
                  <Text style={{ color: "gray", marginHorizontal: 20 }}>
                    Start from scratch to create your own screen with our
                    drag-and-drop editor. Or link to an existing web page,
                    events calendar, etc.
                  </Text>
                </View>
              );
            }
            if (type === TEMPLATES_HEADER) {
              let communityTypePhrase = communityType?.name || "community";
              if (communityType?.key === "other")
                communityTypePhrase = "community";
              return (
                <View style={{ margin: 20, marginBottom: 0, width: "100%" }}>
                  <Text
                    style={[Style.get("headerText"), { marginHorizontal: 20 }]}
                  >
                    Or, start from a template.
                  </Text>
                  <Text style={{ color: "gray", marginHorizontal: 20 }}>
                    These are suggested screens for{" "}
                    {communityTypePhrase === "Montessori school"
                      ? communityTypePhrase
                      : communityTypePhrase.toLowerCase()}{" "}
                    apps.
                  </Text>
                </View>
              );
            }
            if (type === TEMPLATES_BUTTONS) {
              if (!templatePortals?.metadata)
                return (
                  <View style={{ marginVertical: 20 }}>
                    <ActivityIndicator size="large" />
                    <Text style={{ color: "gray", marginTop: 5 }}>
                      Loading your templates...
                    </Text>
                  </View>
                );
              const sortedPortalKeys = Object.keys(templatePortals.metadata);
              sortedPortalKeys.sort((k1, k2) => {
                const p1 = templatePortals.metadata[k1];
                const p2 = templatePortals.metadata[k2];
                // sort alphabetically
                if (p1.txtName < p2.txtName) return -1;
                if (p1.txtName > p2.txtName) return 1;
                return 0;
              });
              return (
                <>
                  {sortedPortalKeys.map((key) => {
                    const templateMetadata = templatePortals.metadata[key];
                    const templateContent = templatePortals.content[key];
                    return (
                      <View
                        key={key}
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between"
                        }}
                      >
                        <Button
                          text={templateMetadata.txtName}
                          icon={
                            templateMetadata.icon || templateMetadata.imgName
                          }
                          onPress={() =>
                            onSelectPortalType(
                              {
                                type: templateMetadata.portalType,
                                name: templateMetadata.txtName,
                                icon:
                                  templateMetadata.icon ||
                                  templateMetadata.imgName,
                                templateContent
                              },
                              true
                            )
                          }
                          options={{ alignLeft: true }}
                        />
                        <View
                          activeOpacity={0.7}
                          style={{ marginLeft: 20, width: 32 }}
                        />
                      </View>
                    );
                  })}
                </>
              );
            }
            if (type === OTHER_HEADER) {
              return (
                <View style={{ margin: 20, marginBottom: 0, width: "100%" }}>
                  <Text
                    style={[Style.get("headerText"), { marginHorizontal: 20 }]}
                  >
                    Want something completely different?
                  </Text>
                </View>
              );
            }
            const portalTypeDetails = {
              type,
              ...Util.portalTypeToDetails(type)
            };
            const { nameVerb, icon } = portalTypeDetails;
            return (
              <View
                key={type}
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <Button
                  text={nameVerb}
                  icon={icon}
                  onPress={() => onSelectPortalType(portalTypeDetails)}
                  options={{ alignLeft: true }}
                />
                <TouchableOpacity
                  activeOpacity={0.7}
                  style={{ marginLeft: 20 }}
                  onPress={() => explainPortalType(type)}
                >
                  <FontAwesome5
                    name="question-circle"
                    size={32}
                    color={Rex.getConfig()?.colors?.button}
                  />
                </TouchableOpacity>
              </View>
            );
          })}
        </ScrollView>
        <AlertModal alert={alert} setAlert={setAlert} />
      </View>
    );
  }

  if (fetchingContent) return null;

  const { name: portalTypeName } = Util.portalTypeToDetails(
    metadata.portalType
  );

  if (isSubmitted) {
    const notification = {
      title: "New Screen!",
      body: `A new screen called "${content?.title}" has been created in the app. Check it out!`,
      portalID: createdPortalID
    };
    const indexToAddPortal = parseInt(positionToAddPortalForEveryone, 10) - 1;
    return (
      <View style={styles.pageContent}>
        <NavBar
          navigation={navigation}
          text={
            isNew
              ? `Created ${portalTypeName} Screen`
              : `Saved ${portalTypeName} Screen`
          }
        />
        <ScrollView
          style={{ flex: 1, paddingHorizontal: 20, width }}
          scrollIndicatorInsets={{ right: 1 }}
        >
          <StatusMessage
            type="success"
            message={
              isNew
                ? MESSAGE_CREATED_NEW_PORTAL
                : MESSAGE_UPDATED_EXISTING_PORTAL
            }
          />
          {didAddPortalForEveryone && (
            <View style={{ marginTop: 10 }}>
              <StatusMessage
                type="success"
                message="This portal has been added to everyone's home screen."
              />
            </View>
          )}
          {didSendNotification && (
            <View style={{ marginTop: 10 }}>
              <StatusMessage
                type="success"
                message="Over the next few minutes, your notification will be sent to all users."
              />
            </View>
          )}
          {!didAddPortalForEveryone && (
            <>
              {!isAddingPortalForEveryone ? (
                <>
                  <Text
                    style={{
                      ...Style.get("headerText"),
                      marginTop: 50,
                      marginBottom: 20
                    }}
                  >
                    Want to add it to{" "}
                    <Text style={{ fontStyle: "italic" }}>everyone's</Text> home
                    screen?
                  </Text>
                  <Button
                    text="Add for Everyone"
                    icon="plus"
                    outline
                    onPress={() => setIsAddingPortalForEveryone(true)}
                  />
                  <HelpText text="If you don't add for everyone, users can add it to their own home screens later if they want." />
                </>
              ) : (
                <>
                  <Text
                    style={{
                      ...Style.get("headerText"),
                      marginTop: 50,
                      marginBottom: 20
                    }}
                  >
                    Where on everyone's home screen?
                  </Text>
                  <Button
                    text="Add as the last portal"
                    icon="plus"
                    outline={positionToAddPortalForEveryone !== null}
                    onPress={() => addPortalForEveryone()}
                  />
                  <Text
                    style={{
                      color: "gray",
                      textAlign: "center",
                      marginBottom: 15
                    }}
                  >
                    (recommended)
                  </Text>
                  <Button
                    text="Add as the first portal"
                    icon="announcements"
                    outline
                    onPress={() => addPortalForEveryone(0)}
                  />
                  <Text
                    style={{
                      color: "gray",
                      textAlign: "center",
                      marginBottom: 15
                    }}
                  >
                    (maximum visibility)
                  </Text>
                  {positionToAddPortalForEveryone === null ? (
                    <>
                      <Button
                        text="Add in a specific spot"
                        icon="customize"
                        outline
                        onPress={() => setPositionToAddPortalForEveryone("1")}
                      />
                      <Text
                        style={{
                          color: "gray",
                          textAlign: "center",
                          marginBottom: 15
                        }}
                      >
                        (advanced)
                      </Text>
                    </>
                  ) : (
                    <>
                      <InputBox
                        key="positionToAddPortal"
                        header="Position"
                        keyboardType="number-pad"
                        description="Specify an integer indicating at which position to add this portal."
                        value={positionToAddPortalForEveryone}
                        onChangeText={setPositionToAddPortalForEveryone}
                      />
                      <Button
                        text={
                          indexToAddPortal > -1 &&
                          !Number.isNaN(indexToAddPortal)
                            ? `Add as portal #${indexToAddPortal + 1}`
                            : "Specify a position"
                        }
                        icon="customize"
                        disabled={
                          indexToAddPortal < 0 || Number.isNaN(indexToAddPortal)
                        }
                        color={
                          indexToAddPortal < 0 || Number.isNaN(indexToAddPortal)
                            ? "lightgray"
                            : null
                        }
                        flat
                        onPress={() => addPortalForEveryone(indexToAddPortal)}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
          {!didSendNotification && !!allowedToSendNotifications && isNew && (
            <>
              <Text
                style={{
                  ...Style.get("headerText"),
                  marginTop: 60,
                  marginBottom: 10
                }}
              >
                Want to notify all your users about this new screen?
              </Text>
              {didPreviewNotification && (
                <>
                  <Text>Notification Preview:</Text>
                  <View
                    style={{
                      backgroundColor: "lightgray",
                      padding: 10,
                      borderRadius: 10,
                      marginBottom: 20
                    }}
                  >
                    <Text style={{ fontSize: 16, fontWeight: "bold" }}>
                      {notification.title}
                    </Text>
                    <Text style={{ fontSize: 16 }}>{notification.body}</Text>
                  </View>
                </>
              )}
              {didPreviewNotification ? (
                <Button
                  text="Send Notification"
                  icon="send"
                  onPress={() => sendNotification(notification)}
                />
              ) : (
                <Button
                  text="Preview Notification"
                  icon="eye"
                  outline
                  onPress={() => setDidPreviewNotification(true)}
                />
              )}
              <HelpText
                text={`This is only recommended if you think all your users would like to know immediately about "${content?.title}".`}
              />
            </>
          )}
        </ScrollView>
      </View>
    );
  }

  let fontSize = 0.039 * width;
  const numChars = metadata.txtName.length;
  const multiplier = metadata.txtName.indexOf(" ") > -1 ? 0.2 : 0.5;
  if (numChars > 8) {
    fontSize -= multiplier * numChars;
    if (fontSize < 8) fontSize = 8; // ensure a minimum font size
  }
  if (Glob.deviceIsTablet()) fontSize *= 0.6;

  const allAccountTypeCheckboxes = allAccountTypes
    ? Object.entries(allAccountTypes).map(([type, details]) => ({
        key: type,
        text: details?.title || type
      }))
    : [];
  allAccountTypeCheckboxes.sort((t1, t2) => {
    // sort alphabetically
    if (t1.text < t2.text) return -1;
    if (t1.text > t2.text) return 1;
    return 0;
  });
  const allowedAccountTypeCheckboxes = allAccountTypeCheckboxes.map(
    ({ key, text }) => ({
      key,
      value: !!metadata.allowedAccountTypes?.[key],
      text
    })
  );
  const accountTypesAllowedToPostCheckboxes = allAccountTypeCheckboxes.map(
    ({ key, text }) => ({
      key,
      value: !!content?.accountTypesAllowedToPost?.[key],
      text
    })
  );
  const accountTypesAllowedToCommentCheckboxes = allAccountTypeCheckboxes.map(
    ({ key, text }) => ({
      key,
      value: !!content?.accountTypesAllowedToComment?.[key],
      text
    })
  );
  const screenIsHiddenFromAllAccountTypes =
    !!metadata.allowedAccountTypes &&
    Object.keys(metadata.allowedAccountTypes).length === 1 &&
    metadata.allowedAccountTypes[Glob.get("hiddenScreenAccountTypeKey")] ===
      true;
  const allAccountTypesAreAllowedToAccess = !metadata?.allowedAccountTypes;
  const allAccountTypesAreAllowedToPost = !content?.accountTypesAllowedToPost;
  const allAccountTypesAreAllowedToComment = !content?.accountTypesAllowedToComment;
  let screenWord = "screen";
  if (metadata.portalType === "activityFeed") {
    screenWord = content?.feedType === "chat" ? "chat" : "feed";
  }

  // For now, only allow editors for existing dynamic portals and activity feeds
  const portalCanHaveEditors =
    !isNew && ["dynamic", "activityFeed"].includes(metadata?.portalType);
  const thereAreMorePermissionsToShow =
    (allowedToManageUsers && portalCanHaveEditors) ||
    metadata?.portalType === "activityFeed";
  const numberOfPortalEditors = Object.keys(portalEditors || {}).length;

  const shouldShowMorePermissions =
    showMorePermissions ||
    !allAccountTypesAreAllowedToPost ||
    !allAccountTypesAreAllowedToComment ||
    numberOfPortalEditors > 0;

  return (
    <KeyboardAvoidingView
      style={styles.pageContent}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <NavBar
        navigation={navigation}
        text={
          isNew
            ? `New ${portalTypeName} Screen`
            : `Edit ${portalTypeName} Screen`
        }
      />

      <View
        style={[
          styles.topContainer,
          contentIsExpanded ? { flex: 4 } : { flex: 0.6 }
        ]}
      >
        {contentIsExpanded && (
          <View style={{ width, flexDirection: "row" }}>
            <View
              style={[
                styles.topLeftContainer
                // { flex: Math.min(3 - 3 * (scrollY/100), 3) }
              ]}
            >
              <Animated.View style={{ transform: [{ scale: 0.9 }] }}>
                <TileStatic
                  icon={metadata.icon || metadata.imgName} // backward-compatible
                  iconURL={metadata.iconURL}
                  text={metadata.txtName}
                  tileStyle={[
                    styles.tile,
                    { borderColor: Rex.getConfig()?.colors?.button }
                  ]}
                  textStyle={[
                    styles.tileText,
                    {
                      color: Rex.getConfig()?.colors?.button,
                      fontSize: Glob.deviceIsiPhoneX()
                        ? 0.85 * fontSize
                        : fontSize
                    }
                  ]}
                  tileTextSection={styles.tileTextSection}
                />
              </Animated.View>
              <View
                style={{
                  flex: 1,
                  width: "100%",
                  paddingLeft: 40,
                  alignItems: "center"
                }}
              >
                <MaterialCommunityIcons
                  name="subdirectory-arrow-right"
                  size={70}
                  color="#CCC"
                />
              </View>
            </View>
            <View style={styles.topRightContainer}></View>
          </View>
        )}
        <TouchableOpacity
          activeOpacity={0.6}
          style={styles.expandContentButton}
          onPress={() => {
            setContentIsExpanded(!contentIsExpanded);
          }}
        >
          <>
            {!contentIsExpanded && (
              <Text style={{ marginTop: 10, fontSize: 18, color: "gray" }}>
                Show Preview
              </Text>
            )}
            <MaterialIcons
              name={`expand-${contentIsExpanded ? "less" : "more"}`}
              size={24}
              color="black"
            />
          </>
        </TouchableOpacity>
      </View>

      <View style={styles.bottomContainer}>
        <ScrollView
          showsVerticalScrollIndicator={false}
          keyboardDismissMode="on-drag"
          // onScroll={handleScroll}
          // scrollEventThrottle={16}
        >
          <InputBox
            key="text"
            header="Title"
            value={metadata.txtName}
            onChangeText={(title) => {
              setContent({ ...content, title });
              setMetadata({ ...metadata, txtName: title });
              setMiniScreenKey(Math.random());
            }}
          />

          <View style={{ marginTop: 0.03 * height }} key="active">
            <Text style={Style.get("headerText")}>Icon</Text>
            {metadata.iconURL ? (
              <Text>
                This screen uses a custom icon set by the Onespot team.
              </Text>
            ) : (
              <IconPicker
                icon={metadata.icon || metadata.imgName} // backward-compatible
                onTogglePicker={(showPicker) => {
                  if (showPicker) setContentIsExpanded(false);
                }}
                onSelectIcon={(icon) => setMetadata({ ...metadata, icon })}
              />
            )}
          </View>

          {/* <TouchableOpacity
            activeOpacity={0.7}
            style={{
              marginTop: 0.03 * height,
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between"
            }}
            key="type"
            onPress={() => explainPortalType()}
          >
            <View>
              <Text style={Style.get("headerText")}>Type</Text>
              <Text style={styles.questionDescription}>{portalTypeName}</Text>
            </View>
            <View>
              <FontAwesome5
                name="question-circle"
                size={32}
                color={Rex.getConfig()?.colors?.button}
              />
            </View>
          </TouchableOpacity> */}

          {metadata.portalType === "webNav" && (
            <InputBox
              key="url"
              header="Link"
              onChangeText={(url) => setContent({ ...content, url })}
              value={content?.url}
              navigation={navigation}
              browseForLinkOptions={{
                show: true,
                originalURL: "https://www.google.com", // content?.url
                onPickURL: (newURL) => setContent({ ...content, url: newURL }),
                disabled: false
              }}
            />
          )}

          {metadata.portalType === "pdfDocument" && (
            <>
              <Text style={{ ...Style.get("headerText"), marginTop: 25 }}>
                Document
              </Text>
              <Text>
                {content?.documentName || "Upload a PDF to be displayed."}
              </Text>
              <DocumentPicker
                isNew={!content?.url}
                onUpload={(url, name) => {
                  Analytics.logEvent("action_editPortal_uploadedPDF", {
                    url,
                    documentName: name
                  });
                  setContent({ ...content, url, documentName: name });
                  setMiniScreenKey(Math.random());
                }}
              />
            </>
          )}

          {metadata.portalType === "events" && (
            <>
              <InputBox
                key="googleCalendarID"
                header="Google Calendar ID"
                value={content?.googleCalendarID}
                onChangeText={(googleCalendarID) => {
                  setContent({ ...content, googleCalendarID });
                  setMiniScreenKey(Math.random());
                }}
              />
              <TouchableLink
                type="web"
                link="https://1spot.app/?app=-N9c3gfF3YFVaM2FIstS&screen=-NDFTW6ay8QOtFHytUab"
                text="How do I find a Google Calendar ID?"
                textStyle={{ textDecorationLine: "underline" }}
                navigation={navigation}
              />
            </>
          )}

          {metadata.portalType === "activityFeed" && (
            <>
              {!isNew && (
                <>
                  <Text style={{ ...Style.get("headerText"), marginTop: 25 }}>
                    Display Style
                  </Text>
                  <Checkbox
                    checked={content?.feedType === "chat"}
                    radio
                    text={
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>Group Chat</Text>,
                        like WhatsApp or Slack
                      </Text>
                    }
                    onChange={() => {
                      setContent({ ...content, feedType: "chat" });
                      setMiniScreenKey(Math.random());
                    }}
                  />
                  <Checkbox
                    checked={content?.feedType !== "chat"}
                    radio
                    text={
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>
                          Activity Feed
                        </Text>
                        , like Facebook or Quora
                      </Text>
                    }
                    onChange={() => {
                      setContent({ ...content, feedType: "feed" });
                      setMiniScreenKey(Math.random());
                    }}
                  />
                </>
              )}
              <InputBox
                key="activityFeedTopic"
                header="Discussion Topic"
                description="Set this if you want the discussions in this feed to be focused on a particular topic"
                placeholder="Optional"
                value={content?.feedTopic}
                multiline
                onChangeText={(feedTopic) => {
                  setContent({ ...content, feedTopic });
                  setMiniScreenKey(Math.random());
                }}
              />
              <Text style={{ ...Style.get("headerText"), marginTop: 25 }}>
                Engagement
              </Text>
              <Checkbox
                checked={!content?.disableLikes}
                text="Allow likes"
                onChange={(allow) => {
                  setContent({ ...content, disableLikes: !allow });
                  setMiniScreenKey(Math.random());
                }}
              />
              <Checkbox
                checked={!content?.disableComments}
                text={`Allow ${
                  content?.feedType === "chat" ? "replies" : "comments"
                }`}
                onChange={(allow) => {
                  setContent({ ...content, disableComments: !allow });
                  setMiniScreenKey(Math.random());
                }}
              />
            </>
          )}

          {(metadata.portalType === "dynamic" ||
            (metadata.portalType === "activityFeed" &&
              content?.feedType !== "chat")) && (
            <InputBox
              key="backgroundColor"
              header="Background Color"
              onChangeText={(backgroundColor) => {
                setContent({ ...content, backgroundColor });
                setMiniScreenKey(Math.random());
              }}
              value={content?.backgroundColor}
              colorPicker
            />
          )}

          {Rex.getConfig()?.granularSecurityEnabled && (
            <View>
              <Text style={{ ...Style.get("headerText"), marginTop: 25 }}>
                Permissions <OnespotProBadge />
              </Text>
              <Text style={{ ...Style.get("subheaderText"), marginTop: 10 }}>
                Visibility
              </Text>
              <Checkbox
                radio
                checked={!screenIsHiddenFromAllAccountTypes}
                text={
                  <Text>
                    This screen is{" "}
                    <Text style={{ fontWeight: "bold" }}>visible</Text> in the
                    app
                  </Text>
                }
                onChange={allowAllAccountTypes}
              />
              <Checkbox
                radio
                checked={screenIsHiddenFromAllAccountTypes}
                text={
                  <Text>
                    This screen is{" "}
                    <Text style={{ fontWeight: "bold" }}>hidden</Text> from
                    everyone
                  </Text>
                }
                onChange={allowNoAccountTypes}
              />
              {!screenIsHiddenFromAllAccountTypes && (
                <>
                  <Text
                    style={{ ...Style.get("subheaderText"), marginTop: 10 }}
                  >
                    Access
                  </Text>
                  <Checkbox
                    radio
                    checked={
                      !metadata.restrictedToFeedSubscribers &&
                      allAccountTypesAreAllowedToAccess
                    }
                    text={
                      <Text>
                        <Text style={{ fontWeight: "bold" }}>All users</Text>{" "}
                        have access to this {screenWord}
                      </Text>
                    }
                    onChange={allowAllAccountTypes}
                  />
                  {metadata.portalType === "activityFeed" && (
                    <Checkbox
                      radio
                      checked={!!metadata.restrictedToFeedSubscribers}
                      text={
                        <Text>
                          Only users{" "}
                          <Text style={{ fontWeight: "bold" }}>
                            {content?.feedType === "chat"
                              ? "in this chat "
                              : "subscribed to this feed "}
                          </Text>
                          have access
                        </Text>
                      }
                      onChange={restrictFeedToSubscribers}
                    />
                  )}
                  <Checkbox
                    radio
                    checked={!allAccountTypesAreAllowedToAccess}
                    text={
                      <Text>
                        Restrict access to{" "}
                        {!allAccountTypesAreAllowedToAccess
                          ? "these"
                          : "specific"}{" "}
                        <Text style={{ fontWeight: "bold" }}>
                          account types
                        </Text>
                        {!allAccountTypesAreAllowedToAccess ? ":" : ""}
                      </Text>
                    }
                    onChange={(checked) => {
                      if (checked) allowAllAccountTypesIndividually();
                    }}
                  />

                  {!allAccountTypesAreAllowedToAccess && (
                    <ScrollView
                      style={{ maxHeight: height / 2, marginLeft: 35 }}
                      scrollIndicatorInsets={{ right: 1 }}
                    >
                      <Multiselect
                        items={allowedAccountTypeCheckboxes}
                        onToggle={(key, value) =>
                          onToggleAllowedAccountType(key, value)
                        }
                      />
                    </ScrollView>
                  )}
                  {thereAreMorePermissionsToShow && (
                    <>
                      {!shouldShowMorePermissions ? (
                        <Button
                          small
                          text="Show more permissions"
                          flat
                          outline
                          align="left"
                          onPress={() => setShowMorePermissions(true)}
                          style={{ marginTop: 10 }}
                        />
                      ) : (
                        <>
                          {metadata.portalType === "activityFeed" && (
                            <>
                              <Text
                                style={{
                                  ...Style.get("subheaderText"),
                                  marginTop: 10
                                }}
                              >
                                Messages
                              </Text>
                              <Checkbox
                                radio
                                checked={allAccountTypesAreAllowedToPost}
                                text={
                                  <Text>
                                    <Text style={{ fontWeight: "bold" }}>
                                      All{" "}
                                      {metadata.restrictedToFeedSubscribers
                                        ? `${
                                            content?.feedType === "chat"
                                              ? "chat members"
                                              : "feed subscribers"
                                          }`
                                        : "users"}
                                    </Text>{" "}
                                    can post in this {screenWord}
                                  </Text>
                                }
                                onChange={allowAllAccountTypesToPost}
                              />
                              <Checkbox
                                radio
                                checked={!allAccountTypesAreAllowedToPost}
                                text={
                                  <Text>
                                    Only{" "}
                                    {!allAccountTypesAreAllowedToPost
                                      ? "these"
                                      : "specific"}{" "}
                                    <Text style={{ fontWeight: "bold" }}>
                                      account types
                                    </Text>{" "}
                                    can post messages
                                    {!allAccountTypesAreAllowedToPost
                                      ? ":"
                                      : ""}
                                  </Text>
                                }
                                onChange={(checked) => {
                                  if (checked)
                                    allowAllAccountTypesToPostIndividually();
                                }}
                              />

                              {!allAccountTypesAreAllowedToPost && (
                                <ScrollView
                                  style={{
                                    maxHeight: height / 2,
                                    marginLeft: 35
                                  }}
                                  scrollIndicatorInsets={{ right: 1 }}
                                >
                                  <Multiselect
                                    items={accountTypesAllowedToPostCheckboxes}
                                    onToggle={(key, value) =>
                                      onToggleAccountTypeAllowedToPost(
                                        key,
                                        value
                                      )
                                    }
                                  />
                                </ScrollView>
                              )}

                              {!content?.disableComments && (
                                <>
                                  <Text
                                    style={{
                                      ...Style.get("subheaderText"),
                                      marginTop: 10
                                    }}
                                  >
                                    {content?.feedType === "chat"
                                      ? "Replies"
                                      : "Comments"}
                                  </Text>
                                  <Checkbox
                                    radio
                                    checked={allAccountTypesAreAllowedToComment}
                                    text={
                                      <Text>
                                        <Text style={{ fontWeight: "bold" }}>
                                          All{" "}
                                          {metadata.restrictedToFeedSubscribers
                                            ? `${
                                                content?.feedType === "chat"
                                                  ? "chat members"
                                                  : "feed subscribers"
                                              }`
                                            : "users"}
                                        </Text>{" "}
                                        can{" "}
                                        {content?.feedType === "chat"
                                          ? "reply to"
                                          : "comment on"}{" "}
                                        messages
                                      </Text>
                                    }
                                    onChange={allowAllAccountTypesToComment}
                                  />
                                  <Checkbox
                                    radio
                                    checked={
                                      !allAccountTypesAreAllowedToComment
                                    }
                                    text={
                                      <Text>
                                        Only{" "}
                                        {!allAccountTypesAreAllowedToComment
                                          ? "these"
                                          : "specific"}{" "}
                                        <Text style={{ fontWeight: "bold" }}>
                                          account types
                                        </Text>{" "}
                                        can{" "}
                                        {content?.feedType === "chat"
                                          ? "reply to"
                                          : "comment on"}{" "}
                                        messages
                                        {!allAccountTypesAreAllowedToComment
                                          ? ":"
                                          : ""}
                                      </Text>
                                    }
                                    onChange={(checked) => {
                                      if (checked)
                                        allowAllAccountTypesToCommentIndividually();
                                    }}
                                  />

                                  {!allAccountTypesAreAllowedToComment && (
                                    <ScrollView
                                      style={{
                                        maxHeight: height / 2,
                                        marginLeft: 35
                                      }}
                                      scrollIndicatorInsets={{ right: 1 }}
                                    >
                                      <Multiselect
                                        items={
                                          accountTypesAllowedToCommentCheckboxes
                                        }
                                        onToggle={(key, value) =>
                                          onToggleAccountTypeAllowedToComment(
                                            key,
                                            value
                                          )
                                        }
                                      />
                                    </ScrollView>
                                  )}
                                </>
                              )}
                            </>
                          )}
                          {allowedToManageUsers && portalCanHaveEditors && (
                            <>
                              <Text
                                style={{
                                  ...Style.get("subheaderText"),
                                  marginTop: 10
                                }}
                              >
                                {screenWord.capitalize()} Editors
                                {metadata.portalType === "activityFeed"
                                  ? "/Moderators"
                                  : ""}
                              </Text>
                              <Button
                                small
                                text={`${
                                  numberOfPortalEditors < 1 ? "Add" : "Manage"
                                } Editors`}
                                flat
                                // outline
                                align="left"
                                style={{ marginHorizontal: 0 }}
                                textStyle={{ fontWeight: "bold" }}
                                onPress={() => setIsSettingEditors(true)}
                              />
                              {portalEditorChangesSaved && (
                                <Text style={{ color: "#2DD881" }}>
                                  <Octicons
                                    name="check"
                                    size={14}
                                    color="#2DD881"
                                  />{" "}
                                  Saved changes to screen editors
                                </Text>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </View>
          )}

          {metadata.portalType === "events" && !showAdvancedSettings && (
            <Button
              small
              outline
              style={{ marginTop: 30 }}
              text="Advanced"
              onPress={() => setShowAdvancedSettings(true)}
            />
          )}

          {metadata.portalType === "events" && showAdvancedSettings && (
            <View>
              <Text
                style={{
                  ...Style.get("headerText"),
                  marginTop: 25
                }}
              >
                Multi-Day Events
              </Text>
              <Checkbox
                key="splitUpMultiDayEvents"
                text="Show multi-day events as multiple all-day events"
                checked={content?.splitUpMultiDayEvents}
                onChange={(splitUpMultiDayEvents) => {
                  setContent({ ...content, splitUpMultiDayEvents });
                  setMiniScreenKey(Math.random());
                }}
              />
              <InputBox
                key="hoursOffset"
                header="Hours Offset"
                description='This is occasionally useful for correcting timezone issues with your underlying calendar. Specify a value (such as "+1", "-3", or "-12.5") to add or subtract that number of hours for every event.'
                value={content?.hoursOffset}
                onChangeText={(hoursOffset) => {
                  setContent({ ...content, hoursOffset });
                  setMiniScreenKey(Math.random());
                }}
              />
            </View>
          )}

          {metadata.portalType === "events" &&
            showAdvancedSettings &&
            content?.iCalURL && (
              <InputBox
                key="iCalLink"
                header="iCal Link"
                description="If you don't have a Google Calendar ID, you can instead connect to your calendar with its iCal link"
                value={content?.iCalURL}
                onChangeText={(iCalURL) => {
                  setContent({ ...content, iCalURL });
                  setMiniScreenKey(Math.random());
                }}
              />
            )}

          {metadata.portalType === "events" &&
            showAdvancedSettings &&
            content?.googleSheetID && (
              <InputBox
                key="googleSheetID"
                header="Google Sheet ID"
                value={content?.googleSheetID}
                onChangeText={(googleSheetID) => {
                  setContent({ ...content, googleSheetID });
                  setMiniScreenKey(Math.random());
                }}
              />
            )}

          <View style={{ marginVertical: 30 }}>
            {metadata.portalType === "dynamic" && (
              <Button
                text={`${
                  isNew && !hasEditedDynamicContent ? "Add" : "Edit"
                } Content`}
                textStyle={{ fontWeight: "bold" }}
                onPress={onPressEditContent}
              />
            )}
            <Button
              text={isNew ? "Create" : "Save Changes"}
              style={{ opacity: canSave ? 1 : 0.3 }}
              textStyle={{ fontWeight: "bold" }}
              outline={!canSave}
              disabled={!canSave}
              onPress={() => onSubmitResponse(metadata, content)}
            />
            {!isNew && allowedToEditAllPortals && (
              <>
                {metadata.portalType !== "activityFeed" && (
                  <Button
                    text="Duplicate Screen"
                    small
                    outline
                    style={{ opacity: !canSave ? 1 : 0.3 }}
                    textStyle={{ fontWeight: "bold" }}
                    disabled={canSave}
                    onPress={duplicatePortal}
                  />
                )}
                <Button
                  text="Delete Screen"
                  small
                  outline
                  color={Glob.get("dangerRed")}
                  textStyle={{ fontWeight: "bold" }}
                  onPress={onPressDelete}
                />
              </>
            )}
          </View>
        </ScrollView>
      </View>

      {contentIsExpanded && (
        <Animated.View
          style={{
            position: "absolute",
            height: "66%",
            width,
            borderColor: "#CCC",
            borderWidth: 2,
            elevation: 7,
            transform: [
              { scale: 0.6 },
              { translateY: -0.055 * height },
              { translateX: 0.25 * width }
            ]
          }}
          pointerEvents="none"
        >
          <MiniScreen
            key={miniScreenKey}
            navigation={navigation}
            portalType={metadata?.portalType}
            navName={metadata?.navName}
            contentTitle={content?.title || metadata?.txtName}
            contentURL={content?.url}
            backgroundColor={content?.backgroundColor}
            dynamicContent={content?.content}
            portalContent={content}
          />
        </Animated.View>
      )}
      {isSettingEditors && (
        <BottomSheetModal
          onClose={() => setIsSettingEditors(false)}
          content={
            <>
              <HelpText
                text={`Selected members are allowed to edit this ${
                  content?.title ? `"${content?.title}" ` : ""
                }${screenWord}. Note that admins with the ${`"${Glob.get(
                  "allAdminPrivileges"
                )?.EditAllPortals?.name ||
                  "all screens"}"`} superpower can always edit this ${screenWord}.`}
                style={{ paddingBottom: 0 }}
              />
              <UserList
                navigation={navigation}
                showCheckboxes
                userIDsChecked={portalEditors}
                hideInvitations
                hideTopSummary
                disableUserEditing
                shouldShowShareOptions={false}
                onPress={({ user }) => {
                  if (user?.uid) onToggleUserEditor(user.uid);
                }}
              />
            </>
          }
          fullStyle
        />
      )}
      <AlertModal alert={alert} setAlert={setAlert} />
    </KeyboardAvoidingView>
  );
}

const topContainer = {
  flex: 4,
  width: "100%",
  flexDirection: "row",
  backgroundColor: "white",
  shadowOpacity: 0.3,
  shadowRadius: 15,
  shadowOffset: { width: 0, height: 0 },
  justifyContent: "center",
  elevation: 7
};
if (Platform.OS === "web")
  topContainer.boxShadow = "0px 0px 15px rgba(0,0,0,0.3)";
const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  },
  topContainer,
  topLeftContainer: {
    flex: 2,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  topRightContainer: {
    flex: 4,
    flexDirection: "row"
  },
  bottomContainer: {
    flex: 4,
    width,
    paddingHorizontal: 15
  },
  questionDescription: {
    fontSize: 0.021 * height,
    color: "gray"
  },
  formDescription: {
    marginVertical: 20
  },

  /* Style for the tiles for the home screen */
  tile: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    width: TILE_WIDTH,
    height: TILE_HEIGHT,
    paddingBottom: 0.03 * height,
    marginTop: 0.017 * width,
    margin: 0.04 * width,
    borderRadius: 0.178 * width,
    borderWidth: 0.003 * height
  },

  /* Style for the tiles' text for the home screen */
  tileText: {
    paddingTop: MODULE_TEXT_PADDING,
    fontSize: Glob.deviceIsiPhoneX()
      ? 0.7 * MODULE_FONT_SIZE
      : MODULE_FONT_SIZE,
    fontWeight: "500",
    textAlign: "center"
  },

  /* Style for the section of the tiles that holds the text */
  tileTextSection: {
    marginTop: -0.012 * height,
    marginBottom: 0.012 * height,
    width: 1.15 * TILE_WIDTH,
    alignSelf: "center"
  },

  expandContentButton: {
    justifyContent: "center",
    alignItems: "center",
    opacity: 0.8,
    position: "absolute",
    bottom: 0,
    width: "100%",
    alignSelf: "center",
    marginTop: 10
  }
});

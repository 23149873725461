import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  Image,
  ScrollView,
  KeyboardAvoidingView,
  Platform
} from "react-native";
import ConfettiCannon from "react-native-confetti-cannon";
import Glob from "src/globalConstants";
import Analytics from "src/backend/analytics";
import Database from "src/backend/database";
import Util from "src/utility";
import School from "school/school";
import Rex from "src/globalState";
import NavBar from "src/components/navBar";
import InputBox from "src/components/InputBox";
import Button from "src/components/Button";
import CircularProgress from "src/components/CircularProgress";

const { height, width } = Glob.get("dimensions");
const DEFAULT_LOADING_MESSAGE =
  "Reading your website and building your app. Please don't refresh this page, this could take up to 2 minutes...";
const DEFAULT_STILL_LOADING_MESSAGE = "Our bots are still hard at work...";
const DEFAULT_FINISHED_MESSAGE =
  "We finished! Join your app now to check it out.";

export default function OnespotCreateFromWebsite({ navigation, route }) {
  const { params: { communityTypeName, fullName } = {} } = route || {};
  const [website, setWebsite] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [finishedLoadingText, setFinishedLoadingText] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [builtAppID, setBuiltAppID] = useState(null);
  const [percentLoaded, setPercentLoaded] = useState(0);

  useEffect(() => {
    Analytics.logEvent("view_onespotCreateFromWebsite");
  }, []);

  const joinApp = () => {
    Analytics.logEvent("touch_onespotCreateFromWebsite_openApp", {
      appID: builtAppID
    });
    if (Platform.OS === "web")
      Util.openURL(`https://1spot.app?app=${builtAppID}`);
    else {
      School.setDatabaseAppID(builtAppID);
      Database.fetchGlobalConfig().then((configData) => {
        Rex.setConfig(configData);
        navigation.replace("welcome", { fullName });
      });
    }
  };

  const onPressMakeApp = async () => {
    Analytics.logEvent("touch_onespotCreateFromWebsite_makeApp", { website });
    setIsLoading(true);
    setErrorMessage(null);
    const loadingInterval = setInterval(() => {
      // It will never reach 100% and it'll slow down as it gets closer
      setPercentLoaded((percent) => {
        const newPercent = percent + 1 + Util.randomInt(8);
        if (newPercent < 100) return newPercent;
        return percent;
      });
    }, 4800);
    const messages = await Database.generateAppCreationLoadingMessageFromWebsite(
      website
    );
    const {
      success,
      loadingMessage = DEFAULT_LOADING_MESSAGE,
      stillLoadingMessage = DEFAULT_STILL_LOADING_MESSAGE,
      finishedMessage = DEFAULT_FINISHED_MESSAGE
    } = messages || {};
    console.log("messages");
    console.log(messages);
    if (success) {
      setLoadingText(loadingMessage);
      setFinishedLoadingText(finishedMessage);
      setTimeout(() => {
        setLoadingText((text) => `${text}\n\n${stillLoadingMessage}`);
      }, 25000);
      const response = await Database.onespotCreateNewAppFromWebsiteWithAI(
        website
      );
      console.log("response");
      console.log(response);
      const { appID } = response || {};
      if (appID) {
        Analytics.logEvent("action_onespotCreateFromWebsite_appBuilt", {
          website,
          appID
        });
        clearInterval(loadingInterval);
        setPercentLoaded(100);
        setTimeout(() => {
          setBuiltAppID(appID);
          Util.localStorageGetItemAsync("createdAppIDs").then((ids) => {
            const prevCreatedAppIDs = JSON.parse(ids) || [];
            const newCreatedAppIDs = [...prevCreatedAppIDs, appID];
            Util.localStorageSetItemAsync(
              "createdAppIDs",
              JSON.stringify(newCreatedAppIDs)
            );
          });
          setIsLoading(false);
        }, 1000);
      } else {
        setIsLoading(false);
        clearInterval(loadingInterval);
        setPercentLoaded(0);
        setErrorMessage(
          "Sorry, Onespot AI wasn't able to build your app 😔\nDouble-check your URL or consider trying a different website."
        );
      }
    } else {
      Analytics.logEvent("error_onespotCreateFromWebsite_websiteUnreadable", {
        website
      });
      setIsLoading(false);
      clearInterval(loadingInterval);
      setPercentLoaded(0);
      setErrorMessage(
        "Sorry, Onespot AI wasn't able to read your website 😔\nDouble-check your URL or consider trying a different website."
      );
    }
  };

  return (
    <KeyboardAvoidingView
      style={{
        flex: 1,
        backgroundColor: "white",
        alignItems: "center"
      }}
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      {!!communityTypeName && (
        <NavBar
          navigation={navigation}
          text=""
          backgroundColor="white"
          buttonColor={Glob.get("onespotColor")}
        />
      )}
      <ScrollView
        contentContainerStyle={{ width, alignItems: "center" }}
        showsVerticalScrollIndicator={false}
        keyboardDismissMode="on-drag"
      >
        <View
          style={{ height: 80, width: "80%", marginTop: 100, marginBottom: 40 }}
        >
          <Image
            source={Glob.get("onespotLogoImage")}
            style={{
              flex: 1,
              width: "100%",
              resizeMode: "contain",
              tintColor: Glob.get("onespotColor")
            }}
          />
        </View>
        <View style={{ width, alignItems: "center", paddingHorizontal: 20 }}>
          {builtAppID ? (
            <>
              <Text style={{ fontSize: 24, fontWeight: "500" }}>
                Your app is ready! 🎉
              </Text>
              {Platform.OS === "web" && (
                <Text style={{ fontSize: 16, marginTop: 10, color: "#333" }}>
                  https://1spot.app?app={builtAppID}
                </Text>
              )}
              <Text
                style={{ color: "#888", textAlign: "center", marginTop: 20 }}
              >
                {finishedLoadingText}
              </Text>
              <Button
                text="Join my app"
                onPress={joinApp}
                textStyle={{
                  color: "white",
                  fontSize: 24,
                  fontWeight: "500"
                }}
                style={{
                  marginTop: 40,
                  backgroundColor: Glob.get("onespotColor"),
                  shadowOpacity: 0.2,
                  shadowOffset: { width: 0, height: 0 },
                  shadowRadius: 10,
                  elevation: 10
                }}
              />
            </>
          ) : (
            <>
              <View>
                <InputBox
                  key="website"
                  placeholder="yourwebsite.com"
                  onChangeText={setWebsite}
                  value={website}
                  keyboardType="url"
                />
                {!!errorMessage && (
                  <Text style={{ color: Glob.get("onespotRed") }}>
                    {errorMessage}
                  </Text>
                )}
              </View>
              {isLoading && (
                <View style={{ alignItems: "center", marginTop: 20 }}>
                  <CircularProgress
                    value={percentLoaded}
                    small
                    color={
                      percentLoaded < 100 ? Glob.get("onespotColor") : "#2DD881"
                    }
                  />
                </View>
              )}
              <Text
                style={{ color: "#888", textAlign: "center", marginTop: 20 }}
              >
                {isLoading
                  ? loadingText
                  : `Enter the website of your ${communityTypeName ||
                      "business, organization, or community"}.\nOnespot AI will make you a mobile app in about 2 minutes.`}
              </Text>
              {!isLoading && (
                <Button
                  text="Make my app"
                  onPress={onPressMakeApp}
                  disabled={!website}
                  textStyle={{
                    color: "white",
                    fontSize: 24,
                    fontWeight: "500"
                  }}
                  style={{
                    opacity: website ? 1 : 0.3,
                    marginTop: 40,
                    backgroundColor: Glob.get("onespotColor"),
                    shadowOpacity: 0.2,
                    shadowOffset: { width: 0, height: 0 },
                    shadowRadius: 10,
                    elevation: 10
                  }}
                />
              )}
            </>
          )}
        </View>
      </ScrollView>
      {!!builtAppID && (
        <ConfettiCannon
          count={200}
          origin={{ x: width / 2, y: height / 1.5 }}
          autoStart
        />
      )}
    </KeyboardAvoidingView>
  );
}

import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  Platform
} from "react-native";
import LottieView from "src/components/Lottie";
import Constants from "expo-constants";
import School from "school/school";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Util from "src/utility";
import Style from "src/globalStyles";
import Database from "src/backend/database";
import Firebase from "src/backend/firebase";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import SettingsItem from "src/components/SettingsItem";
import AlertModal from "src/components/AlertModal";
import {
  Ionicons,
  FontAwesome,
  MaterialIcons,
  MaterialCommunityIcons
} from "@expo/vector-icons";
import PrivacyAndTerms from "src/components/PrivacyAndTerms";

const { height, width } = Glob.get("dimensions");
const IS_ONESPOT_APP = Constants.expoConfig.slug === "onespot";
const IS_THE_MONTESSORI_APP = Constants.expoConfig.slug === "montessori";
const IS_ONESPOT_K12 = Constants.expoConfig.slug === "onespotk12";
const IS_ECC_CADDIES_APP_ID = "-N0lElL60kTCJuiZMinl";
let learnMoreURL = "https://www.onespotapps.com";
if (IS_THE_MONTESSORI_APP) learnMoreURL = "https://montessorimobileapps.com";
else if (IS_ONESPOT_K12)
  learnMoreURL = "https://www.onespotapps.com/k-12-schools";

const MORNING_ANIMATION = require("resources/animations/morningSun.json");
const AFTERNOON_ANIMATION = require("resources/animations/landscape.json");
const EVENING_ANIMATION = require("resources/animations/nighttime.json");
const BIRDS_ANIMATION = require("resources/animations/flyingBirds.json");

const HeaderAnimation = ({ currentHour, backgroundColor }) => {
  const [birdsFlew, setBirdsFlew] = useState(false);
  let animation;
  let aspectRatio;
  let loop = true;
  if (currentHour > 17 || currentHour < 4) {
    animation = EVENING_ANIMATION;
    aspectRatio = 1080 / 365;
    loop = false;
  } else if (currentHour < 12) {
    animation = MORNING_ANIMATION;
    aspectRatio = 594 / 222;
  } else {
    animation = AFTERNOON_ANIMATION;
    aspectRatio = 1200 / 675;
  }
  return (
    <TouchableOpacity
      activeOpacity={0.9}
      style={{
        height: width / aspectRatio,
        backgroundColor
      }}
      disabled={birdsFlew}
      onPress={() => {
        Analytics.logEvent("touch_settings_headerImage");
        setBirdsFlew(true);
      }}
    >
      <LottieView
        style={{ height: "100%", width: "100%" }}
        autoPlay
        loop={loop}
        source={animation}
      />
      {birdsFlew && (
        <View
          style={{
            position: "absolute",
            height: width / aspectRatio,
            width
          }}
        >
          <LottieView
            style={{ height: "100%", width: "100%" }}
            autoPlay
            loop={false}
            source={BIRDS_ANIMATION}
            resizeMode="cover"
          />
        </View>
      )}
    </TouchableOpacity>
  );
};

export default function Settings({ navigation, route }) {
  const { checkRefresh } = route?.params || {};
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userGroupsEnabled, setUserGroupsEnabled] = useState(false);
  const [appStoreLink, setAppStoreLink] = useState(null);
  const [appVersionRecommended, setAppVersionRecommended] = useState("0.0.0");
  const [userIsCreator, setUserIsCreator] = useState(false);
  const [billing, setBilling] = useState(null);
  const [alert, setAlert] = useState(null);
  const [caddieEvaluationFormURL, setCaddieEvaluationFormURL] = useState(null);

  useEffect(() => {
    Analytics.logEvent("view_settings");
    Database.fetchAllUserData().then((user) => {
      setUserFirstName(user?.firstName || "");
      setUserLastName(user?.lastName || "");
      setUserEmail(user?.email || "");

      // Only show QR code if this is the ECC Caddies app
      if (School.getDatabaseAppID() === IS_ECC_CADDIES_APP_ID) {
        Database.fetchAllAccountTypeDetails().then((details) => {
          let accountType = "";
          if (details && details[user?.type] && details[user?.type].title)
            accountType = details[user?.type].title;
          setCaddieEvaluationFormURL(
            `https://docs.google.com/forms/d/e/1FAIpQLSedkndO2vijKULdKrD0MuAmCllXpGUTBMwf56mxqjAuERyJOw/viewform?entry.1017457417=${accountType}&entry.597624966=${user?.firstName}&entry.7883898=${user?.lastName}`
          );
        });
      }
    });
    Database.getAllUserGroups().then((groups) => {
      if (groups && Object.keys(groups)?.length > 0) setUserGroupsEnabled(true);
    });
    Database.fetchAppStoreInfo().then((info) => {
      if (info?.version) {
        setAppVersionRecommended(info?.version?.recommended || "");
      } else {
        // backup to ensure backwards compatibility
        Database.listenAppVersion((recommendedVersion) => {
          setAppVersionRecommended(recommendedVersion);
        });
      }

      if (info?.appLinks) {
        setAppStoreLink(
          Platform.OS === "ios" ? info.appLinks.ios : info.appLinks.android
        );
      } else {
        // for backwards compatibility
        setAppStoreLink(
          Platform.OS === "ios"
            ? School.get("app link ios")
            : School.get("app link android")
        );
      }
    });
    Database.fetchBillingInfo().then((billingInfo) => {
      setBilling(billingInfo);
    });
    Database.fetchAppMetadata().then((appMetadata) => {
      setUserIsCreator(appMetadata?.creator === Firebase.getUserID());
    });
  }, []);

  const navigate = (screenName, props = {}) => {
    navigation.push(screenName, props);
  };

  const logoutConfirm = () => {
    setAlert({
      title: "Do you want to sign out?",
      cancel: {
        text: "Cancel",
        onPress: () => console.log("Logout Cancelled")
      },
      confirm: { text: "Sign out", onPress: logout }
    });
  };

  const logout = () => {
    Rex.setLoginStatus(false);
    Analytics.logEvent("touch_settings_logoutConfirm");
    Database.logoutUser()
      .then(() => Util.reloadApp())
      .catch((error) => Util.alert(`Error: ${error}`));
  };

  const renderAppVersionText = () => {
    const v = Glob.get("appVersion");
    const vFull = Glob.get("appVersionFull");
    let versionText = `App Version: ${vFull} (up to date)`;
    // If user has the current version, text box is grayed out and not interactive
    if (Database.compareVersions(v, appVersionRecommended)) {
      return (
        <Text style={[styles.textFieldText, { marginBottom: 40 }]}>
          {versionText}
        </Text>
      );
    }
    // Update is available, so link to the appropriate app store
    versionText = `App Version: ${vFull} (update available!)`;
    return (
      <SettingsItem
        text={versionText}
        icon={
          <MaterialIcons
            name="system-update"
            size={22}
            color="#b47474" // red
          />
        }
        onPress={() => {
          if (appStoreLink) Util.openURL(appStoreLink);
        }}
        isRed
      />
    );
  };

  const billingIsStripe = !!billing?.subscription?.stripeID;
  let revenueCatSubscriptionManagementURL = null;
  if (
    !billingIsStripe &&
    billing?.customer?.details?.originalAppUserId === Firebase.getUserID()
  )
    revenueCatSubscriptionManagementURL =
      billing?.customer?.details?.managementURL;
  const canManageBilling =
    !!billing &&
    userIsCreator &&
    (billingIsStripe || !!revenueCatSubscriptionManagementURL);
  const billingManagementURL = billingIsStripe
    ? `${Glob.get("stripeCustomerPortalLink")}?prefilled_email=${userEmail}`
    : revenueCatSubscriptionManagementURL;

  const treatAsMetaApp = !Util.appIsStandalone();

  const currentHour = new Date().getHours();
  let greeting;
  let backgroundColor;
  if (currentHour > 17 || currentHour < 4) {
    greeting = "Good Evening";
    backgroundColor = "#1f0041";
  } else if (currentHour < 12) {
    greeting = "Good Morning";
    backgroundColor = "white";
  } else {
    greeting = "Good Afternoon";
    backgroundColor = "#b3d9f2";
  }
  return (
    <View style={{ flex: 1, backgroundColor }}>
      <NavBar navigation={navigation} text="Profile" />
      <ScrollView
        contentContainerStyle={
          Platform.OS === "web" ? { alignItems: "center" } : {}
        }
        scrollIndicatorInsets={{ right: 1 }}
      >
        <View style={{ maxHeight: "40%", maxWidth: width }}>
          <HeaderAnimation
            currentHour={currentHour}
            backgroundColor={backgroundColor}
          />
        </View>
        <View style={styles.settingsContainer}>
          <View style={styles.headerTextContainer}>
            <Text
              style={[
                styles.textHeader,
                { color: Rex.getConfig()?.colors?.text }
              ]}
            >
              {userFirstName ? `${greeting}, ${userFirstName}` : `${greeting}`}
            </Text>
          </View>

          <View style={styles.sectionDivider} />

          <View style={styles.settingsItemsContainer}>
            {!!caddieEvaluationFormURL && (
              <SettingsItem
                text="My caddie evaluation card"
                icon={
                  <MaterialCommunityIcons
                    name="qrcode-scan"
                    size={22}
                    color={Glob.get("gray")}
                  />
                }
                onPress={() => {
                  Analytics.logEvent("touch_settings_displayQRCode");
                  navigate("displayQRCode", {
                    title: "Caddie Evaluation Card",
                    qrCode: caddieEvaluationFormURL,
                    TopContent: () => (
                      <>
                        <Text
                          style={[
                            Style.get("headerText"),
                            { fontSize: 40, textAlign: "center", marginTop: 30 }
                          ]}
                        >
                          {userFirstName} {userLastName}
                        </Text>
                        <Text
                          style={[
                            Style.get("subheaderText"),
                            {
                              fontSize: 20,
                              textAlign: "center",
                              marginBottom: 10
                            }
                          ]}
                        >
                          Scan to provide feedback on the performance of{" "}
                          {userFirstName} {userLastName}:
                        </Text>
                      </>
                    )
                  });
                }}
              />
            )}

            <SettingsItem
              text="My profile"
              icon={
                <Ionicons
                  name="person-outline"
                  size={22}
                  color={Glob.get("gray")}
                />
              }
              onPress={() => {
                Analytics.logEvent("touch_settings_editProfile");
                navigate("editProfile", {
                  checkRefresh
                });
              }}
            />

            {userGroupsEnabled && (
              <>
                <SettingsItem
                  text="My groups"
                  icon={
                    <MaterialCommunityIcons
                      name="account-group-outline"
                      size={22}
                      color={Glob.get("gray")}
                    />
                  }
                  onPress={() => {
                    Analytics.logEvent("touch_settings_groups");
                    navigate("editUserGroups", {
                      checkRefresh
                    });
                  }}
                />
              </>
            )}

            <SettingsItem
              text="Notification preferences"
              icon={
                <Ionicons
                  name="notifications-outline"
                  size={22}
                  color={Glob.get("gray")}
                />
              }
              onPress={() => {
                Analytics.logEvent("touch_settings_notificationPreferences");
                navigate("notificationPreferences", {
                  checkRefresh
                });
              }}
            />

            {canManageBilling && (
              <SettingsItem
                text="Billing & subscription management"
                icon={
                  <FontAwesome
                    name="credit-card"
                    size={22}
                    color={Glob.get("gray")}
                  />
                }
                onPress={() => {
                  Analytics.logEvent("touch_settings_billing");
                  Util.openURL(billingManagementURL);
                }}
              />
            )}

            <SettingsItem
              text="Account settings"
              icon={
                <MaterialIcons
                  name="lock-outline"
                  size={22}
                  color={Glob.get("gray")}
                />
              }
              onPress={() => {
                Analytics.logEvent("touch_settings_changePassword");
                navigate("changePassword", {
                  userIsCreator,
                  canManageBilling,
                  billingManagementURL
                });
              }}
            />

            <SettingsItem
              text="Sign out"
              icon={
                <MaterialIcons
                  name="logout"
                  size={22}
                  color="#b47474" // red
                />
              }
              onPress={() => {
                Analytics.logEvent("touch_settings_signOut");
                logoutConfirm();
              }}
              isRed
            />
          </View>

          <View style={styles.headerTextContainer}>
            <Text
              style={[
                styles.textHeader,
                { color: Rex.getConfig()?.colors?.text }
              ]}
            >
              {treatAsMetaApp
                ? `You're in ${Rex.getConfig()?.names?.nickname}`
                : `The ${Rex.getConfig()?.names?.nickname} App`}
            </Text>
          </View>

          <View style={styles.sectionDivider} />

          {treatAsMetaApp && (
            <View style={styles.settingsItemsContainer}>
              <SettingsItem
                text="Switch spots"
                icon={
                  <MaterialIcons
                    name="swap-horiz"
                    size={22}
                    color={Glob.get("gray")}
                  />
                }
                onPress={() => {
                  Analytics.logEvent("touch_settings_switchSpots");
                  navigate("onespotJoin", {
                    navigatedFromProfile: true,
                    fullName: `${userFirstName} ${userLastName}`
                  });
                }}
              />
            </View>
          )}

          {IS_ONESPOT_APP && (
            <View style={styles.settingsItemsContainer}>
              <SettingsItem
                text="Make a new spot"
                icon={
                  <Ionicons
                    name="create-outline"
                    size={22}
                    color={Glob.get("gray")}
                  />
                }
                onPress={() => {
                  Analytics.logEvent("touch_settings_makeAnApp");
                  navigate("onespotCreateType", {
                    navigatedFromProfile: true,
                    fullName: `${userFirstName} ${userLastName}`
                  });
                }}
              />
            </View>
          )}

          <View style={styles.settingsItemsContainer}>
            <SettingsItem
              text="Share this app"
              icon={
                <Ionicons
                  name="share-social-outline"
                  size={22}
                  color={Glob.get("gray")}
                />
              }
              onPress={() => {
                Analytics.logEvent("touch_settings_shareApp");
                navigation.push("shareApp");
              }}
            />

            <SettingsItem
              text="Leave a suggestion"
              icon={
                <MaterialCommunityIcons
                  name="lightbulb-on-outline"
                  size={22}
                  color={Glob.get("gray")}
                />
              }
              onPress={() => {
                Analytics.logEvent("touch_settings_suggestionBox");
                navigation.push("dynamicForm", {
                  navName: "SuggestionBox",
                  isGlobalForm: true
                });
              }}
            />

            {Rex.getConfig()?.appDesigner?.name && (
              <SettingsItem
                text={`Designed by ${Rex.getConfig().appDesigner.name}`}
                icon={
                  <MaterialIcons
                    name="design-services"
                    size={22}
                    color={Glob.get("gray")}
                  />
                }
                onPress={() => {
                  Analytics.logEvent("touch_settings_appDesigner");
                  if (Rex.getConfig().appDesigner.link) {
                    navigate("webNav", {
                      title: Rex.getConfig().appDesigner.name,
                      url: Rex.getConfig().appDesigner.link
                    });
                  }
                }}
              />
            )}

            <SettingsItem
              text="Powered by onespot"
              icon={
                <MaterialCommunityIcons
                  name="lightning-bolt"
                  size={22}
                  color={Glob.get("gray")}
                />
              }
              onPress={() => {
                Analytics.logEvent("touch_settings_learnMore");
                navigate("webNav", {
                  title: "Onespot",
                  url: learnMoreURL
                });
              }}
            />

            <PrivacyAndTerms
              navigation={navigation}
              style={styles.textFieldText}
            />

            {renderAppVersionText()}
          </View>
        </View>
      </ScrollView>
      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}

const styles = StyleSheet.create({
  settingsContainer: {
    width,
    minHeight: "100%",
    backgroundColor: "white"
  },

  /* Style for the container holding the section header text */
  headerTextContainer: {
    paddingHorizontal: 15,
    paddingTop: 0.03 * height
  },

  /* Style for the container holding the section header text */
  settingsItemsContainer: {
    paddingHorizontal: 15
  },

  /* Style for a section header text */
  textHeader: {
    fontSize: 24,
    marginBottom: 0.006 * height
  },

  /* Style for a text field that cannot be touched */
  textFieldText: {
    color: "#b4b4b4",
    fontSize: 14,
    marginTop: 10,
    marginBottom: 10,
    textAlign: "center"
  },

  /* Style for a divider to go between sections on the page */
  sectionDivider: {
    height: 1,
    width,
    backgroundColor: "rgba(206, 206, 206, 1)"
  }
});

import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  ScrollView,
  SafeAreaView,
  Platform
} from "react-native";
import Constants from "expo-constants";
import LottieView from "src/components/Lottie";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Style from "src/globalStyles";
import Analytics from "src/backend/analytics";
import Button from "src/components/Button";
import NavBar from "src/components/navBar";
import HelpButton from "src/components/HelpButton";

const MEDITATION_ANIMATION = require("resources/animations/meditation.json");

const { height, width, fullWidth } = Glob.get("dimensions");
const IS_ONESPOT = Constants.expoConfig.slug === "onespot";
const COMMUNITY_TYPE_OTHER = {
  example: "Juggling Club",
  key: "other",
  name: "Other",
  userTypes: ["member"]
};
// If it's in StyleSheet it causes issues on web
const BUTTON_TEXT_STYLE = {
  color: Glob.get("primaryColor"),
  marginRight: 5,
  textAlign: "left"
};

export default function OnespotCreateType({ navigation, route }) {
  const { params: { fullName } = {} } = route || {};
  const [loaded, setLoaded] = useState(false);
  const [step, setStep] = useState(0);
  const [buttonsToShow, setButtonsToShow] = useState("overview"); // "overview", "orgs", or "all"
  const [selectedCommunityTypeName, setSelectedCommunityTypeName] = useState(
    "organization"
  );
  const communityTypes = Glob.getAllCommunityTypes();

  useEffect(() => {
    Analytics.logEvent("view_onespotCreateType");
    setLoaded(true);
  }, []);

  const selectOrgType = (type) => {
    setSelectedCommunityTypeName((type.name || "organization").toLowerCase());
    Analytics.logEvent("touch_onespotCreateType_selectType", { type });
    Rex.setSessionMemory("newAppType", type.key);
    if (IS_ONESPOT) setStep(2);
    else navigation.push("onespotCreateDetails", { fullName });
  };

  // Should only be within the Onespot app
  const selectHaveWebsite = (haveWebsite) => {
    Analytics.logEvent("touch_onespotCreateType_selectHaveWebsite", {
      haveWebsite,
      selectedCommunityTypeName
    });
    if (haveWebsite)
      navigation.push("onespotCreateFromWebsite", {
        fullName,
        communityTypeName: selectedCommunityTypeName
      });
    else navigation.push("onespotCreateDetails", { fullName });
    if (IS_ONESPOT) setStep(1);
  };

  const CommunityTypeButton = ({ type, name }) => {
    const typeObj = Glob.getCommunityType(type) || {};
    return (
      <Button
        text={name || typeObj.name}
        onPress={() => selectOrgType(typeObj)}
        textStyle={BUTTON_TEXT_STYLE}
        style={styles.button}
        icon={typeObj.icon || null}
      />
    );
  };

  if (step === 0)
    return (
      <SafeAreaView style={{ height, backgroundColor: "white" }}>
        <NavBar
          navigation={navigation}
          text=""
          backgroundColor="white"
          buttonColor={Glob.get("onespotColor")}
        />
        <View
          style={{
            flex: 2,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <LottieView
            style={{ height: "100%", width: "100%" }}
            autoPlay
            loop
            source={MEDITATION_ANIMATION}
          />
        </View>
        <View
          style={{
            flex: 2,
            justifyContent: "center",
            alignItems: "center",
            marginHorizontal: 20
          }}
        >
          <View style={{ width: Glob.deviceIsTablet() ? "50%" : "100%" }}>
            <Text
              style={{
                fontSize: 32,
                color: Glob.get("primaryColor"),
                fontWeight: "bold",
                textAlign: "center"
              }}
            >
              {Rex.getMetaApp()?.appCreation?.explanationHeader ||
                "Make a mobile app"}
            </Text>
            {Rex.getMetaApp()?.appCreation?.explanationDescription ? (
              <Text
                style={{
                  fontSize: 16,
                  color: Glob.get("primaryColor"),
                  width: "100%",
                  marginBottom: 20
                }}
              >
                {Rex.getMetaApp().appCreation.explanationDescription}
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 16,
                  color: Glob.get("primaryColor"),
                  width: "100%",
                  marginBottom: 20,
                  textAlign: "center"
                }}
              >
                <Text>for your business, organization, or community</Text>
                <Text style={{ fontWeight: "bold" }}>
                  {" "}
                  in 15 minutes, with no code, right{" "}
                  {Platform.OS === "web" ? "now. " : "from your phone. "}
                </Text>
                <Text>Seriously.</Text>
              </Text>
            )}
          </View>
          <Button
            text="Get started"
            onPress={() => {
              Analytics.logEvent("touch_onespotCreateType_getStarted");
              if (!IS_ONESPOT) selectOrgType(COMMUNITY_TYPE_OTHER);
              else setStep(1);
            }}
            textStyle={styles.getStartedButtonText}
            style={styles.getStartedButton}
          />
        </View>
      </SafeAreaView>
    );

  if (step === 1)
    return (
      <View style={styles.mainView}>
        <NavBar
          isOnespot
          navigation={navigation}
          text="Create"
          RightButton={
            <View style={{ alignItems: "flex-end" }}>
              <HelpButton
                title="Creating an App"
                message="You can make a brand new app for your in 60 seconds."
                navigation={navigation}
                videoUrl="https://youtu.be/s9m6JUfsTlY"
              />
            </View>
          }
        />
        <Text style={[Style.get("headerText"), styles.questionText]}>
          {buttonsToShow === "orgs"
            ? "What type of organization/community?"
            : "What will your app be for?"}
        </Text>
        <View style={{ flex: 5, alignItems: "center", width: fullWidth }}>
          {loaded && (
            <ScrollView
              style={{ width: fullWidth, height: "100%" }}
              scrollIndicatorInsets={{ right: 1 }}
            >
              {buttonsToShow === "overview" && (
                <View style={{ marginBottom: 50 }}>
                  <CommunityTypeButton
                    type="company"
                    name="My business/company"
                  />
                  <Button
                    text="My organization/community"
                    onPress={() => setButtonsToShow("orgs")}
                    textStyle={BUTTON_TEXT_STYLE}
                    style={styles.button}
                    icon="people"
                  />
                  <CommunityTypeButton type="school" name="My school/college" />
                  <CommunityTypeButton
                    type="project"
                    name="A personal project"
                  />
                  <Button
                    text="A mobile game"
                    onPress={() => {
                      navigation.push("onespotCreateTypeGame");
                    }}
                    textStyle={BUTTON_TEXT_STYLE}
                    style={styles.button}
                    icon="7c0c2579-d6b9-4f47-8f2b-b23c2b90dde8"
                  />
                  <Button
                    text="Something else"
                    onPress={() => setButtonsToShow("all")}
                    textStyle={BUTTON_TEXT_STYLE}
                    style={styles.button}
                    icon="82a0c53a-9445-4f7f-8e20-2addb0bf6f76"
                  />
                </View>
              )}
              {buttonsToShow === "orgs" && (
                <View style={{ marginBottom: 50 }}>
                  <CommunityTypeButton type="clubTeam" />
                  <CommunityTypeButton type="neighborhood" />
                  <CommunityTypeButton type="town" />
                  <CommunityTypeButton type="faithGroup" />
                  <CommunityTypeButton type="enterpriseTeam" />
                  <CommunityTypeButton type="conference" />
                  <Button
                    text="Something else"
                    onPress={() => setButtonsToShow("all")}
                    textStyle={BUTTON_TEXT_STYLE}
                    style={styles.button}
                    icon="82a0c53a-9445-4f7f-8e20-2addb0bf6f76"
                  />
                </View>
              )}
              {buttonsToShow === "all" && (
                <View style={{ marginBottom: 50 }}>
                  {communityTypes.map((type) => (
                    <CommunityTypeButton type={type.key} />
                  ))}
                </View>
              )}
            </ScrollView>
          )}
        </View>
      </View>
    );

  if (step === 2)
    return (
      <View style={styles.mainView}>
        <NavBar
          isOnespot
          navigation={navigation}
          text="Create"
          RightButton={
            <View style={{ alignItems: "flex-end" }}>
              <HelpButton
                title="Creating an App"
                message="You can make a brand new app for your in 60 seconds."
                navigation={navigation}
                videoUrl="https://youtu.be/s9m6JUfsTlY"
              />
            </View>
          }
        />
        <Text style={[Style.get("headerText"), styles.questionText]}>
          Does your {selectedCommunityTypeName} have a website already?
        </Text>
        <View
          style={{
            flexDirection: "row",
            width: width * 0.9,
            alignSelf: "center"
          }}
        >
          <Button
            text="Yes"
            onPress={() => selectHaveWebsite(true)}
            textStyle={{
              color: Glob.get("primaryColor"),
              marginRight: 5,
              textAlign: "center",
              fontWeight: "bold"
            }}
            style={[styles.button, { flex: 1 }]}
          />
          <Button
            text="No"
            onPress={() => selectHaveWebsite(false)}
            textStyle={{
              color: Glob.get("primaryColor"),
              marginRight: 5,
              textAlign: "center",
              fontWeight: "bold"
            }}
            style={[styles.button, { flex: 1 }]}
          />
        </View>
      </View>
    );
}

const button = {
  backgroundColor: "white",
  marginTop: 6,
  marginBottom: 3,
  width: "90%",
  maxWidth: 600, // just for tablets
  shadowOpacity: 0.2,
  shadowOffset: { width: 0, height: 0 },
  shadowRadius: 4,
  elevation: 4
};
const getStartedButton = {
  backgroundColor: "white",
  marginBottom: 20,
  shadowOpacity: 0.3,
  shadowOffset: { width: 0, height: 0 },
  shadowRadius: 5,
  elevation: 10,
  height: 80
};
if (Platform.OS === "web") {
  button.boxShadow = "0px 0px 4px rgba(0,0,0,0.2)";
  getStartedButton.boxShadow = "0px 0px 5px rgba(0,0,0,0.3)";
}

const styles = StyleSheet.create({
  questionText: {
    textAlign: Glob.deviceIsTablet() ? "center" : "left",
    marginTop: 20,
    marginBottom: 10,
    marginHorizontal: 25,
    color: Glob.get("primaryColor")
  },
  questionDescriptionText: {
    textAlign: Glob.deviceIsTablet() ? "center" : "left",
    marginTop: 10,
    marginBottom: 20,
    marginHorizontal: 25
  },

  mainView: {
    flex: 1,
    height: "100%",
    backgroundColor: "white"
  },

  button,

  getStartedButton,

  getStartedButtonText: {
    color: Glob.get("primaryColor"),
    fontSize: 32
  }
});

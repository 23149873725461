import React, { useState, useEffect } from "react";
import { StyleSheet, View, TouchableOpacity, Text } from "react-native";
import Icon from "src/components/Icon";
import Analytics from "src/backend/analytics";
import NavBar from "src/components/navBar";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import UserList from "src/components/UserList";
import BottomSheetModal from "src/components/BottomSheetModal";
import Button from "src/components/Button";

const { width } = Glob.get("dimensions");
const ICON_SIZE = Math.min(0.07 * width, Glob.deviceIsTablet() ? 42 : 26);
const LINK_BLUE = "#007AFF";

export default function ActivityFeedManageUsers({ navigation, route }) {
  const { activityFeedID, isChatStyle, restrictedToFeedSubscribers } =
    route?.params || {};
  const addWord = restrictedToFeedSubscribers ? "add" : "subscribe";
  const removeWord = restrictedToFeedSubscribers ? "remove" : "unsubscribe";
  const [subscribers, setSubscribers] = useState(null);
  const [addingRemovingUsersState, setAddingRemovingUsersState] = useState(
    null
  ); // null, addWord, or removeWord
  const [
    filteredUsersPhraseForAddingRemoving,
    setFilteredUsersPhraseForAddingRemoving
  ] = useState("");
  const [
    numberOfUsersForAddingRemoving,
    setNumberOfUsersForAddingRemoving
  ] = useState(0);
  const [
    shouldCallProcessFilteredUsers,
    setShouldCallProcessFilteredUsers
  ] = useState(false);

  const numberOfSubscribers = subscribers?.length || 0;

  useEffect(() => {
    Analytics.logEvent("view_activityFeedManageUsers");
    Database.subscribeToActivityFeedSubscribers(activityFeedID, setSubscribers);
    return () =>
      Database.unsubscribeFromActivityFeedSubscribers(activityFeedID);
  }, []);

  const processFilteredUsers = (users) => {
    const userIDs = users.map((u) => u.uid);
    setShouldCallProcessFilteredUsers(false);
    Analytics.logEvent(
      `touch_activityFeedManageUsers_${
        addingRemovingUsersState === addWord ? "add" : "remove"
      }Users`,
      { activityFeedID, userIDs }
    );
    Database.subscribeToReceiveActivityFeedNotifications(
      activityFeedID,
      addingRemovingUsersState === addWord,
      userIDs
    );
    // If we just removed ALL members, close the modal
    if (
      addingRemovingUsersState === removeWord &&
      userIDs?.length === numberOfSubscribers &&
      userIDs?.every((id) => subscribers?.includes(id))
    )
      setAddingRemovingUsersState(null);
  };

  const noUsersToAddRemove = numberOfUsersForAddingRemoving < 1;
  const noSubscribers = numberOfSubscribers < 1;
  const chatFeedWord = isChatStyle ? "chat" : "feed";
  const toFromWord = addingRemovingUsersState === addWord ? "to" : "from";

  const AddMembersButton = (
    <TouchableOpacity
      style={{
        width: "100%",
        height: "100%",
        alignItems: "flex-end",
        justifyContent: "center",
        paddingRight: 20
      }}
      activeOpacity={0.6}
      onPress={() => setAddingRemovingUsersState(addWord)}
    >
      <Icon
        icon="1900a003-7a62-449b-8db0-979fbeef4876"
        color="white"
        size={ICON_SIZE}
      />
    </TouchableOpacity>
  );
  let RemoveMembersButton = null;
  if (!noSubscribers)
    RemoveMembersButton = (
      <TouchableOpacity
        style={{
          width: "100%",
          height: "100%",
          alignItems: "flex-end",
          justifyContent: "center"
        }}
        activeOpacity={0.6}
        onPress={() => setAddingRemovingUsersState(removeWord)}
      >
        <Icon
          icon="37a6c868-e472-434d-9800-e51f144386a5"
          color="white"
          size={ICON_SIZE}
        />
      </TouchableOpacity>
    );

  return (
    <View style={styles.pageContent}>
      <NavBar
        navigation={navigation}
        text={isChatStyle ? "Chat Members" : "Members"}
        RightButton={AddMembersButton}
        RightButtonSecondary={RemoveMembersButton}
      />
      {noSubscribers ? (
        <View style={{ alignItems: "center" }}>
          <Text style={{ fontSize: 16, margin: 40 }}>
            There are no members{" "}
            {restrictedToFeedSubscribers ? "in" : "subscribed to"} this{" "}
            {chatFeedWord} yet.
          </Text>
          <Button
            text="Add Members"
            onPress={() => setAddingRemovingUsersState(addWord)}
          />
        </View>
      ) : (
        <UserList
          navigation={navigation}
          filteredUserIDs={subscribers}
          hideInvitations
          disableUserEditing
          filteredMessageSuffix={`${numberOfSubscribers === 1 ? "is" : "are"} ${
            restrictedToFeedSubscribers ? "in" : "subscribed to"
          } this ${chatFeedWord}`}
          shouldShowShareOptions={false}
          renderCustomTopContent={(user) => {
            return (
              <Button
                text={`${removeWord.capitalize()} from ${chatFeedWord}`}
                onPress={() => {
                  const { uid } = user || {};
                  if (uid) {
                    Analytics.logEvent(
                      `touch_activityFeedManageUsers_removeUserInEditor`,
                      { activityFeedID, user: uid }
                    );
                    Database.subscribeToReceiveActivityFeedNotifications(
                      activityFeedID,
                      false,
                      [uid]
                    );
                    navigation.goBack();
                  }
                }}
              />
            );
          }}
        />
      )}
      {!!addingRemovingUsersState && (
        <BottomSheetModal
          onClose={() => setAddingRemovingUsersState(null)}
          content={
            <>
              <View style={{ paddingHorizontal: 20 }}>
                <Text
                  style={{
                    textAlign: "center",
                    fontSize: 16,
                    fontWeight: "bold"
                  }}
                >
                  Select a member to {addingRemovingUsersState} them{" "}
                  {toFromWord} this {chatFeedWord}.
                </Text>
                <TouchableOpacity
                  style={{
                    alignItems: "center",
                    paddingTop: 10,
                    paddingBottom: 0,
                    paddingHorizontal: 20
                  }}
                  disabled={noUsersToAddRemove}
                  activeOpacity={0.6}
                  onPress={() => setShouldCallProcessFilteredUsers(true)}
                >
                  {noUsersToAddRemove ? (
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 16,
                        color: "gray"
                      }}
                    >
                      There are no other {filteredUsersPhraseForAddingRemoving}.
                    </Text>
                  ) : (
                    <Text
                      style={{
                        textAlign: "center",
                        fontSize: 16,
                        fontWeight: "bold"
                      }}
                    >
                      Or{" "}
                      <Text
                        style={{
                          color:
                            addingRemovingUsersState === removeWord
                              ? Glob.get("onespotRed")
                              : LINK_BLUE,
                          textDecorationLine: "underline"
                        }}
                      >
                        {addingRemovingUsersState} all{" "}
                        {numberOfUsersForAddingRemoving}{" "}
                        {filteredUsersPhraseForAddingRemoving}
                      </Text>
                      .
                    </Text>
                  )}
                </TouchableOpacity>
              </View>
              <UserList
                navigation={navigation}
                filteredUserIDs={
                  addingRemovingUsersState === addWord ? null : subscribers
                }
                filteredUserIDsToExclude={
                  addingRemovingUsersState === removeWord ? null : subscribers
                }
                hideInvitations
                hideTopSummary
                disableUserEditing
                shouldShowShareOptions={false}
                onChangeFilteredUsersPhrase={
                  setFilteredUsersPhraseForAddingRemoving
                }
                onChangeNumberOfUsers={setNumberOfUsersForAddingRemoving}
                shouldCallProcessFilteredUsers={shouldCallProcessFilteredUsers}
                processFilteredUsers={processFilteredUsers}
                onPress={({ user }) => {
                  if (user?.uid) {
                    Analytics.logEvent(
                      `touch_activityFeedManageUsers_${
                        addingRemovingUsersState === addWord ? "add" : "remove"
                      }User`,
                      { activityFeedID, user: user.uid }
                    );
                    Database.subscribeToReceiveActivityFeedNotifications(
                      activityFeedID,
                      addingRemovingUsersState === addWord,
                      [user.uid]
                    );
                    // If we just removed the last person, close the modal
                    if (
                      addingRemovingUsersState === removeWord &&
                      numberOfSubscribers === 1 &&
                      user.uid === subscribers[0]
                    )
                      setAddingRemovingUsersState(null);
                  }
                }}
              />
            </>
          }
          fullStyle
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  pageContent: {
    flex: 1,
    alignItems: "center",
    backgroundColor: "white"
  }
});

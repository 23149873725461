import React, { useEffect, useState } from "react";
import {
  Text,
  StyleSheet,
  View,
  Dimensions,
  FlatList,
  ActivityIndicator,
  Platform
} from "react-native";
import Constants from "expo-constants";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import Style from "src/globalStyles";
import Firebase from "src/backend/firebase";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import Util from "src/utility";
import School from "school/school";
import NavBar from "src/components/navBar";
import SearchBar from "src/components/searchBar";
import AppButton from "src/components/AppButton";
import Button from "src/components/Button";

const { height, width } = Dimensions.get("window");
const IS_ONESPOT = Constants.expoConfig.slug === "onespot";
const IS_ONESPOT_CAMPUS = Constants.expoConfig.slug === "onespotcampus";
const IS_MONTESSORI_APP = Constants.expoConfig.slug === "montessori";
const IS_ONESPOT_K12 = Constants.expoConfig.slug === "onespotk12";
const ONESPOT_APP_ID = "ONESPOT";

const DEFAULT_SUGGESTED_APPS = [
  "-MyXnRzWhkzZUHzfQWna", // Wordle World
  "-MyXZjST-lj1xKXmMY8f", // News App
  "la-selva-coffee", // La Selva Coffee
  "-N0CNmLJZ7jsFEjbXgWv", // Golf App
  "-N9c3gfF3YFVaM2FIstS" // Onespot
];

const MONTESSORI_SUGGESTED_APPS = [
  "-N2SIvszM1AkKSC5abvd", // Montessori Foundation
  "-N2SKMfynExUlm3dj3a_", // International Montessori Council
  "-N2SLlk4YSudCiiFndb_", // Montessori Family Alliance
  "-N2SMSgW_zSQcnogFur-", // Montessori Foundation Store
  "-NQT7UnVvxrf4j3duySS" // Montessori Job Board
  // "-N2SOCi1WK-niqVKAV5V", // Montessori School Administrators
  // "-N2SNDYA0sdam3FlUrKH" // Montessori Premium Membership
];
const MONTESSORI_MARKETPLACE_APPS = [
  // "-NPe1k6-Xu7pQvuipp8G", // Montessori Marketplace
  // "-NQT7UnVvxrf4j3duySS" // Montessori Job Board
];
// const MONTESSORI_MARKETPLACE_APP_NAMES = {
//   "-NPe1k6-Xu7pQvuipp8G": "🛒 Support Montessori Businesses", // Montessori Marketplace
//   "-NQT7UnVvxrf4j3duySS": "🧑‍🏫 Explore Montessori Jobs" // Montessori Job Board
// };

// todo: these is hard-coded and temporary
const ECC_CADDIES_APP = "-N0lElL60kTCJuiZMinl";
const ECC_CADDIES_SUGGESTED_APPS = [
  "-MoeGP8aEmdHsLb-XS8j", // Elyria Catholic
  "st-edward-high-school", // St. Ed's
  "-N0CNmLJZ7jsFEjbXgWv", // Golf App
  "-MyXnRzWhkzZUHzfQWna", // Wordle World
  "-MyXZjST-lj1xKXmMY8f" // News App
];
const ST_ELIZABETH_APP = "-MmJC-eKOWx46iaIIvzo";
const ST_ELIZABETH_SUGGESTED_APPS = [
  "-MlqWtg9tDRXUC372Ow3", // Warner Elementary
  "-Mp8V9GhUOHySkSqfZzO", // Cab Calloway
  "-MyXnRzWhkzZUHzfQWna", // Wordle World
  "-MyXZjST-lj1xKXmMY8f", // News App
  "la-selva-coffee", // La Selva Coffee
  "-N9c3gfF3YFVaM2FIstS" // Onespot
];
const CAB_CALLOWAY_APP = "-MmJC-eKOWx46iaIIvzo";
const CAB_CALLOWAY_SUGGESTED_APPS = [
  "-MlqWtg9tDRXUC372Ow3", // Warner Elementary
  "-MmJC-eKOWx46iaIIvzo", // St. Elizabeth
  "-MyXnRzWhkzZUHzfQWna", // Wordle World
  "-MyXZjST-lj1xKXmMY8f", // News App
  "-N9c3gfF3YFVaM2FIstS" // Onespot
];

const COMMUNITY_TYPE = IS_ONESPOT_CAMPUS ? "campus" : "community";

export default function OnespotJoin({ route, navigation }) {
  const {
    params: { navigatedFromProfile, fullName, navigateToApp, appsJoined } = {}
  } = route || {};
  const [allApps, setAllApps] = useState(null);
  const [allAppsFiltered, setAllAppsFiltered] = useState(null);
  const [allAppsGrouped, setAllAppsGrouped] = useState({
    suggested: [],
    created: [],
    appsByCommunityType: {},
    unpublishedAppsVisibleBySuperAdmins: [],
    montessoriMarketplaceApps: []
  });
  const [finishedSwitchingSpots, setFinishedSwitchingSpots] = useState(false);

  useEffect(() => {
    Analytics.logEvent("view_onespotJoin");
    if (navigateToApp) {
      setFinishedSwitchingSpots(true);
      joinApp(navigateToApp);
    }
    const userIsSuperAdmin = Database.userIsSuperAdmin();
    if (!navigateToApp)
      Database.onespotFetchAllAppsMetadata().then((all) => {
        Util.localStorageGetItemAsync("createdAppIDs").then((ids) => {
          const createdAppIDs = !ids ? [] : JSON.parse(ids) || [];
          const allAppsFetched = Object.entries(all)
            .map(([key, value]) => ({
              key,
              userIsCreator:
                createdAppIDs.includes(key) ||
                (!!value.creator && value.creator === Firebase.getUserID()),
              ...value
            }))
            .filter((app) => {
              if (
                IS_ONESPOT_CAMPUS &&
                (!Rex.getLoginStatus() || !Rex.getMetaApp()?.apps)
              )
                return app.key in Glob.get("onespotCampusApps");
              const shouldShow =
                (app.public && !(IS_ONESPOT && app.hideInOnespot)) ||
                app.userIsCreator ||
                userIsSuperAdmin;
              if (Rex.getMetaApp()?.apps)
                return shouldShow && app.key in Rex.getMetaApp().apps;
              return shouldShow;
            });
          const appsSuggestedObject = {};
          const appsSuggested = [];
          const appsCreatedPublished = [];
          const appsCreatedUnpublished = [];
          const appsByCommunityType = { other: [] };
          const unpublishedAppsVisibleBySuperAdmins = [];
          const montessoriMarketplaceApps = [];
          Glob.getAllCommunityTypes().forEach((communityType) => {
            appsByCommunityType[communityType.key] = [];
          });

          let suggestedApps = DEFAULT_SUGGESTED_APPS;
          if (IS_MONTESSORI_APP) {
            suggestedApps = MONTESSORI_SUGGESTED_APPS;
          } else if (appsJoined) {
            if (appsJoined.includes(ECC_CADDIES_APP))
              suggestedApps = ECC_CADDIES_SUGGESTED_APPS;
            else if (appsJoined.includes(ST_ELIZABETH_APP))
              suggestedApps = ST_ELIZABETH_SUGGESTED_APPS;
            else if (appsJoined.includes(CAB_CALLOWAY_APP))
              suggestedApps = CAB_CALLOWAY_SUGGESTED_APPS;
          }

          allAppsFetched.forEach((app) => {
            if (suggestedApps.includes(app.key))
              appsSuggestedObject[app.key] = app;
            else if (
              IS_MONTESSORI_APP &&
              MONTESSORI_MARKETPLACE_APPS.includes(app.key)
            )
              montessoriMarketplaceApps.push(app);
            else if (app.userIsCreator && app.public)
              appsCreatedPublished.push(app);
            else if (app.userIsCreator && !app.public)
              appsCreatedUnpublished.push(app);
            else if (!app.userIsCreator && !app.public)
              unpublishedAppsVisibleBySuperAdmins.push(app);
            else if (
              app.communityType &&
              app.communityType in appsByCommunityType
            )
              appsByCommunityType[app.communityType].push(app);
            else appsByCommunityType.other.push(app);
          });

          // Sort in same order as suggestedApps
          suggestedApps.forEach((key) => {
            if (appsSuggestedObject.hasOwnProperty(key))
              appsSuggested.push(appsSuggestedObject[key]);
          });

          // Alphabetize (note: this seems to not work on web)
          appsCreatedPublished.sort((a, b) => a.name > b.name);
          appsCreatedUnpublished.sort((a, b) => a.name > b.name);
          Object.keys(appsByCommunityType).forEach((communityType) =>
            appsByCommunityType[communityType].sort((a, b) => a.name > b.name)
          );
          unpublishedAppsVisibleBySuperAdmins.sort((a, b) => a.name > b.name);
          allAppsFetched.sort((a, b) => a.name > b.name);

          setAllApps(allAppsFetched);
          setAllAppsFiltered(allAppsFetched);
          setAllAppsGrouped({
            suggested: appsSuggested,
            created: [...appsCreatedPublished, ...appsCreatedUnpublished],
            appsByCommunityType,
            unpublishedAppsVisibleBySuperAdmins,
            montessoriMarketplaceApps
          });
        });
      });

    navigation.addListener("beforeRemove", () => {
      if (Glob.get("appIsOnespotlike") && !Rex.getLoginStatus()) {
        School.setDatabaseAppID(ONESPOT_APP_ID);
        Rex.resetConfig();
      } else {
        Util.localStorageGetItemAsync("databaseAppID").then((appID) => {
          School.setDatabaseAppID(appID);
          Database.fetchGlobalConfig().then((configData) => {
            Rex.setConfig(configData);
          });
        });
      }
    });
  }, []);

  const joinApp = (app) => {
    if (!navigateToApp) Analytics.logEvent("touch_onespotJoin_selectApp", app);
    const { key } = app;
    Util.determineScreenWhenJoiningAppAsync(key, app).then((screenName) => {
      // If this is onespot campus & user just selected a campus to join
      if (IS_ONESPOT_CAMPUS && !navigateToApp) {
        const metaAppID = Glob.get("onespotCampusApps")[key];
        Database.fetchMetaApp(metaAppID).then((metaApp) => {
          Rex.setMetaApp(metaApp);
          navigation.push(screenName, { fullName });
        });
      } else {
        navigation.push(screenName, { fullName });
      }
    });
  };

  const searchToFilterApps = (text) => {
    setAllAppsFiltered(Util.searchItems(allApps, text, "name"));
  };

  const MainContent = () => {
    if (!allAppsFiltered)
      return finishedSwitchingSpots ? null : (
        <ActivityIndicator style={{ marginTop: 80 }} size="large" />
      );
    if (allAppsFiltered.length < 1)
      return (
        <View style={{ alignItems: "center" }}>
          <Text style={styles.noCommunitiesFoundText}>
            No communities found
          </Text>
          <Text style={styles.noCommunitiesFoundTextSecondary}>
            Try searching for something else.
          </Text>
        </View>
      );
    const userHasSearched = allAppsFiltered.length < allApps.length;
    if (!userHasSearched) {
      if (IS_MONTESSORI_APP) {
        const allMontessoriSchoolApps = allAppsFiltered.filter(
          (app) => !MONTESSORI_SUGGESTED_APPS.includes(app.key)
        );
        const allData = [
          { type: "header", title: "Resources" },
          ...allAppsGrouped.suggested.map((app) => ({ type: "app", app })),
          { type: "header", title: "Schools" },
          ...allMontessoriSchoolApps.map((app) => ({ type: "app", app }))
        ];
        return (
          <FlatList
            data={allData}
            keyExtractor={(item) =>
              item.type === "app" ? item.app.key : item.title
            }
            renderItem={({ item }) => {
              if (item.type === "header") {
                return (
                  <Text style={styles.sectionHeaderText}>{item.title}</Text>
                );
              }
              return (
                <View style={{ width }}>
                  <AppButton app={item.app} onPress={joinApp} />
                </View>
              );
            }}
            contentContainerStyle={{
              alignItems: Glob.deviceIsTablet() ? "center" : "flex-start"
            }}
            keyboardDismissMode="on-drag"
            keyboardShouldPersistTaps="always"
            scrollIndicatorInsets={{ right: 1 }}
          />
        );
      }
      if (IS_ONESPOT_K12) {
        return (
          <FlatList
            data={allAppsFiltered}
            keyExtractor={(item) => item?.app?.key}
            renderItem={({ item }) => (
              <View style={{ width }}>
                <AppButton app={item} onPress={joinApp} />
              </View>
            )}
            contentContainerStyle={{
              alignItems: Glob.deviceIsTablet() ? "center" : "flex-start"
            }}
            keyboardDismissMode="on-drag"
            keyboardShouldPersistTaps="always"
            scrollIndicatorInsets={{ right: 1 }}
          />
        );
      }
      if (IS_ONESPOT) {
        const appsListedByCommunityType = [];
        Glob.getAllCommunityTypes().forEach((communityType) => {
          const appsInCommunityType =
            allAppsGrouped.appsByCommunityType[communityType.key];
          if (appsInCommunityType && appsInCommunityType.length > 0) {
            appsListedByCommunityType.push({
              type: "header",
              title: communityType.name
            });
            appsInCommunityType.forEach((app) => {
              appsListedByCommunityType.push({
                type: "app",
                app
              });
            });
          }
        });
        const allData = [];
        if (allAppsGrouped.suggested.length > 0) {
          allData.push({ type: "header", title: "Suggested Apps" });
          allData.push(
            ...allAppsGrouped.suggested.map((app) => ({ type: "app", app }))
          );
        }
        if (allAppsGrouped.created.length > 0) {
          allData.push({ type: "header", title: "Apps You Made" });
          allData.push(
            ...allAppsGrouped.created.map((app) => ({ type: "app", app }))
          );
        }
        if (appsListedByCommunityType.length > 0) {
          allData.push(...appsListedByCommunityType);
        }
        if (allAppsGrouped.unpublishedAppsVisibleBySuperAdmins.length > 0) {
          allData.push({
            type: "header",
            title: "Unpublished Apps (only visible by superadmins)"
          });
          allData.push(
            ...allAppsGrouped.unpublishedAppsVisibleBySuperAdmins.map(
              (app) => ({
                type: "app",
                app: { ...app, name: `🕶 ${app.name}` }
              })
            )
          );
        }
        return (
          <FlatList
            contentContainerStyle={{
              alignItems: Glob.deviceIsTablet() ? "center" : "flex-start"
            }}
            keyboardDismissMode="on-drag"
            keyboardShouldPersistTaps="always"
            data={allData}
            keyExtractor={(item) =>
              item.type === "app" ? item?.app?.key : item.title
            }
            renderItem={({ item }) => {
              if (item.type === "header") {
                return (
                  <Text style={styles.sectionHeaderText}>{item.title}</Text>
                );
              }
              return (
                <View style={{ width }}>
                  <AppButton app={item.app} onPress={joinApp} />
                </View>
              );
            }}
            scrollIndicatorInsets={{ right: 1 }}
          />
        );
      }
    }
    return (
      <FlatList
        data={allAppsFiltered}
        keyExtractor={(item) => item?.app?.key}
        renderItem={({ item }) => (
          <View style={{ width }}>
            <AppButton app={item} onPress={joinApp} />
          </View>
        )}
        contentContainerStyle={{
          alignItems: Glob.deviceIsTablet() ? "center" : "flex-start"
        }}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="always"
        scrollIndicatorInsets={{ right: 1 }}
      />
    );
  };

  const Title = () => {
    if (IS_MONTESSORI_APP || IS_ONESPOT_K12) return null;
    let message = navigatedFromProfile
      ? `Drop into another ${COMMUNITY_TYPE}`
      : `Select your ${COMMUNITY_TYPE}`;
    if (IS_ONESPOT_CAMPUS && Rex.getLoginStatus())
      message = `Drop into another ${Rex.getMetaApp()?.title} community`;
    return (
      <Text style={{ ...Style.get("headerText"), color: "white" }}>
        {message}
      </Text>
    );
  };

  const Subtitle = () => {
    if (IS_ONESPOT)
      return (
        <Text style={{ color: "white", fontSize: 16 }}>
          or, go back and make a new one!
        </Text>
      );
    if (Rex.getMetaApp()?.selectionSubtitle)
      return (
        <Text style={{ color: "white", fontSize: 16 }}>
          {Rex.getMetaApp()?.selectionSubtitle || ""}
        </Text>
      );
    return null;
  };

  return (
    <View style={styles.mainView}>
      <NavBar isOnespot navigation={navigation} text="Join" />
      {!!navigateToApp && !allAppsFiltered ? (
        <View
          style={{
            marginHorizontal: 25,
            marginTop: 20,
            alignItems: Glob.deviceIsTablet() ? "center" : "flex-start"
          }}
        >
          <Text style={{ ...Style.get("headerText"), color: "white" }}>
            Switching apps...
          </Text>
          {finishedSwitchingSpots && (
            <Button
              text="Done"
              onPress={() => navigation.goBack()}
              style={{
                marginTop: height * 0.65,
                backgroundColor: "white"
              }}
              textStyle={{
                color: Glob.get("primaryColor"),
                fontWeight: "bold"
              }}
            />
          )}
        </View>
      ) : (
        <View
          style={{
            marginHorizontal: 25,
            marginTop: 20,
            alignItems: Glob.deviceIsTablet() ? "center" : "flex-start"
          }}
        >
          <Title />
          <Subtitle />
          {allApps?.length > 8 && (
            <SearchBar
              placeholder={
                IS_MONTESSORI_APP || IS_ONESPOT_K12
                  ? "Search for your school"
                  : "Search"
              }
              onChangeText={searchToFilterApps}
            />
          )}
        </View>
      )}
      <MainContent />
      {((IS_ONESPOT_CAMPUS && !Rex.getLoginStatus()) ||
        IS_MONTESSORI_APP ||
        IS_ONESPOT_K12) &&
        !!allAppsFiltered && (
          <View
            style={{
              height: 120,
              width: "90%",
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Text style={{ color: "white", fontSize: 16, textAlign: "center" }}>
              Don't see your school yet?
            </Text>
            {IS_MONTESSORI_APP || IS_ONESPOT_K12 ? (
              <View style={{ flexDirection: "row" }}>
                <Button
                  text="Add a New School"
                  onPress={() => {
                    navigation.push("webNav", {
                      title: "Add Your School",
                      url: IS_MONTESSORI_APP
                        ? Glob.get("formRequestMontessoriApp")
                        : Glob.get("formRequestK12App")
                    });
                  }}
                  style={{
                    marginBottom: 20,
                    backgroundColor: Glob.get("onespotYellow")
                  }}
                  small
                  textStyle={{
                    color: Glob.get("primaryColor"),
                    fontWeight: "bold"
                  }}
                />
                {IS_MONTESSORI_APP && (
                  <Button
                    text="Learn More"
                    onPress={() => {
                      navigation.push("webNav", {
                        title: "Onespot School Apps",
                        url: "https://youtu.be/avE1mxkjf1k"
                      });
                    }}
                    style={{
                      marginBottom: 20,
                      backgroundColor: "#fff8c2"
                    }}
                    small
                    textStyle={{
                      color: Glob.get("primaryColor"),
                      fontWeight: "bold"
                    }}
                  />
                )}
              </View>
            ) : (
              <Text
                style={{ color: "white", fontSize: 16, textAlign: "center" }}
              >
                Just ask! team@seabirdapps.com
              </Text>
            )}
          </View>
        )}
    </View>
  );
}

const button = {
  backgroundColor: "white",
  marginTop: 6,
  marginBottom: 3,
  width: "90%",
  maxWidth: 600, // just for tablets
  shadowOpacity: 0.2,
  shadowOffset: { width: 0, height: 0 },
  shadowRadius: 4,
  elevation: 4
};
if (Platform.OS === "web") button.boxShadow = "0px 0px 4px rgba(0,0,0,0.2)";

const styles = StyleSheet.create({
  questionText: {
    fontSize: 20,
    marginHorizontal: 15,
    fontWeight: "bold",
    color: "white",
    opacity: 0.8
  },

  mainView: {
    height,
    backgroundColor: Glob.get("primaryColor")
  },

  button,

  buttonText: {
    color: Glob.get("primaryColor"),
    marginHorizontal: 15,
    textAlign: "left",
    fontWeight: "500"
  },

  sectionHeaderText: {
    marginHorizontal: 25,
    color: "white",
    fontWeight: "bold",
    opacity: 0.6,
    marginTop: 25,
    fontSize: 30
  },

  noAppsMadeYetText: {
    marginHorizontal: 25,
    color: "white",
    opacity: 0.6,
    fontSize: 18
  },

  noCommunitiesFoundText: {
    color: "white",
    fontWeight: "bold",
    marginTop: 20,
    fontSize: 18
  },

  noCommunitiesFoundTextSecondary: {
    color: "white",
    fontSize: 14
  }
});

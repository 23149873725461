import React, { useState } from "react";
import {
  FlatList,
  StyleSheet,
  ScrollView,
  View,
  SafeAreaView,
  TouchableOpacity,
  Platform
} from "react-native";
import { BlurView } from "expo-blur";
import { AutoDragSortableView } from "react-native-drag-sort";
import { DraggableGrid } from "react-native-draggable-grid";
import { AntDesign, MaterialIcons } from "@expo/vector-icons";
import * as Haptics from "expo-haptics";
import Analytics from "src/backend/analytics";
import Database from "src/backend/database";
import Rex from "src/globalState";
import Glob from "src/globalConstants";
import Util from "src/utility";
import Tile from "src/components/tile";

const { height, width } = Glob.get("dimensions");

const GRID_PADDING_WIDTH = 0.067 * width;

const TILE_WIDTH = Glob.deviceIsTablet() ? 0.8 * 0.278 * width : 0.278 * width;
const TILE_HEIGHT = Glob.deviceIsiPhoneX()
  ? 0.8 * 0.154 * height
  : 0.154 * height;

export default function PageList({
  navigation,
  onUpdatePortal,
  checkRefresh,
  portals,
  isEditing,
  globalConfigOverride,
  userIsAllowedToCreatePortals,
  userHasInactivePortals,
  condense,
  setCurrentPage,
  toggleCustomizingHomeScreen,
  numberOfPages,
  portalsToHide = []
}) {
  const [draggingPortal, setDraggingPortal] = useState(false);

  const onPressAddPortal = (item) => {
    Analytics.logEvent("touch_home_addPortal", {
      key: item.navName,
      title: item.txtName
    });
    const newItem = { ...item };
    delete newItem.isInactivePortal;
    const newPortals = [...Rex.getHomePortals(), newItem];
    const portalKeys = newPortals.map((portal) => portal.navName);
    if (portalKeys.length > 0) Rex.orderChanged(newPortals);
    Util.alert(
      `Added ${
        item.txtName
      } to your home screen! ${Util.randomCelebratoryEmoji()}`
    );
    checkRefresh();
  };

  const onPressRemovePortal = (item, index) => {
    Analytics.logEvent("touch_home_removePortal", {
      key: item.navName,
      title: item.txtName
    });
    const newPortals = portals.filter((p, idx) => idx !== index);
    const portalKeys = newPortals.map((portal) => portal.navName);
    if (portalKeys.length > 0) Rex.orderChanged(newPortals);
    checkRefresh();
  };

  const renderTile = ({ item, index, disabled }) => {
    const globalConfig = globalConfigOverride || Rex.getConfig();
    const showSubscriptionPaywall =
      Rex.getConfig()?.showSubscriptionPaywall || false;
    if (item.isAddPortalsButton || item.isMakeNewPortalButton) {
      return (
        <View style={{ transform: isEditing ? [{ scale: 0.8 }] : [] }}>
          <Tile
            navigation={navigation}
            navName={item.navName}
            icon={
              item.isMakeNewPortalButton
                ? "6a91910e-2aa0-4999-adc6-a15e8c9a15a9"
                : "plus"
            }
            portalType={item.portalType}
            txtName={
              item.isMakeNewPortalButton
                ? "New Screen"
                : "Add To Your Home Screen"
            }
            restrictedToFeedSubscribers={item.restrictedToFeedSubscribers}
            allowedAccountTypes={item.allowedAccountTypes}
            globalConfig={globalConfig}
            onUpdatePortal={onUpdatePortal}
            onPress={() => {
              if (item.isMakeNewPortalButton) {
                if (showSubscriptionPaywall) {
                  navigation.push("onespotCreatorOnboarding", {
                    shouldPublish: false,
                    title: "Ready to add screens?"
                  });
                } else {
                  navigation.push("editPortal", {
                    onUpdatePortal
                  });
                }
              } else
                navigation.push("addPortals", { checkRefresh, portalsToHide });
            }}
            condense={condense}
            darkStyle
          />
        </View>
      );
    }

    return (
      <View
        style={{
          transform: isEditing
            ? [{ scale: Platform.OS === "web" ? 0.4 : 0.8 }]
            : [],
          cursor: isEditing ? "grab" : "auto"
        }}
      >
        <Tile
          navigation={navigation}
          navName={item.navName}
          icon={item.icon || item.imgName} // backward-compatible
          iconURL={item.iconURL}
          portalType={item.portalType}
          txtName={item.txtName}
          restrictedToFeedSubscribers={item.restrictedToFeedSubscribers}
          allowedAccountTypes={item.allowedAccountTypes}
          disabled={disabled}
          isEditing={isEditing}
          globalConfig={globalConfig}
          onUpdatePortal={onUpdatePortal}
          onPress={item.isInactivePortal ? () => onPressAddPortal(item) : null}
          condense={condense}
          toggleCustomizingHomeScreen={toggleCustomizingHomeScreen}
        />
        {(!!item.isInactivePortal || !!isEditing) && (
          <TouchableOpacity
            activeOpacity={0.7}
            style={{
              borderRadius: 30,
              width: 50,
              height: 50,
              position: "absolute",
              right: 0,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: Rex.getConfig()?.colors?.background,
              elevation: 10
            }}
            onPress={() => {
              if (isEditing) onPressRemovePortal(item, index);
              else onPressAddPortal(item);
            }}
          >
            <MaterialIcons
              name={`${isEditing ? "remove" : "add"}-circle`}
              size={45}
              color="white"
              style={{ opacity: isEditing ? 0.6 : 1 }}
            />
          </TouchableOpacity>
        )}
      </View>
    );
  };

  const getViews = () => {
    const homePortals = [...portals];
    if (userHasInactivePortals) homePortals.push({ isAddPortalsButton: true });
    if (userIsAllowedToCreatePortals)
      homePortals.push({ isMakeNewPortalButton: true });
    let moduleList = [];
    const views = [];
    for (let i = 0; i < homePortals.length / 6; i += 1) {
      /* Add a new PageList to "views" for every 6 portals */
      if (i + 1 > homePortals.length / 6) {
        for (let j = 0; j < homePortals.length - 6 * i; j += 1) {
          moduleList[j] = homePortals[6 * i + j];
        }
      } else {
        for (let j = 0; j < 6; j += 1) {
          if (homePortals[6 * i + j]) {
            moduleList[j] = homePortals[6 * i + j];
          }
        }
      }

      views.push({ moduleList });
      moduleList = [];
    }
    return views;
  };

  if (isEditing)
    return (
      <SafeAreaView
        style={{
          height: 0.85 * height,
          width: 0.9 * width,
          alignItems: "center"
        }}
      >
        {Platform.OS === "web" ? (
          <BlurView
            intensity={100}
            style={{ width: TILE_WIDTH * 2, height: "100%" }}
          >
            <ScrollView
              scrollEnabled={!draggingPortal}
              style={{ width: TILE_WIDTH * 2, height: "100%" }}
              contentContainerStyle={{ justifyContent: "center" }}
            >
              <DraggableGrid
                numColumns={2}
                delayLongPress={10}
                itemHeight={TILE_HEIGHT}
                data={portals.map((portal) => ({
                  ...portal,
                  key: portal.navName
                }))}
                renderItem={(item, index) =>
                  renderTile({ item, index, disabled: true })
                }
                onDragStart={() => {
                  if (Platform.OS !== "web") Haptics.selectionAsync();
                  setDraggingPortal(true);
                }}
                onDragRelease={(data) => {
                  if (Platform.OS !== "web") Haptics.selectionAsync();
                  setDraggingPortal(false);
                  Database.addTask("customizeHomeScreen", "done");
                  const portalKeys = data.map((portal) => portal.navName);
                  if (portalKeys.length > 0) Rex.orderChanged(data);
                  checkRefresh();
                }}
              />
            </ScrollView>
          </BlurView>
        ) : (
          <AutoDragSortableView
            dataSource={portals}
            scrollIndicatorInsets={{ right: -1000 }} // this just hides the indicator
            parentWidth={0.9 * width}
            autoThrottle={10}
            delayLongPress={100}
            marginChildrenRight={(width - TILE_WIDTH * 2) / 7}
            marginChildrenLeft={(width - TILE_WIDTH * 2) / 7}
            childrenWidth={TILE_WIDTH}
            childrenHeight={TILE_HEIGHT * 1.4}
            keyExtractor={(item) => item.navName}
            onDragStart={() => {
              if (Platform.OS !== "web") Haptics.selectionAsync();
            }}
            onDragEnd={() => {
              if (Platform.OS !== "web") Haptics.selectionAsync();
            }}
            renderHeaderView={
              <View
                style={{
                  marginTop: 10,
                  position: "absolute",
                  alignSelf: "center"
                }}
              >
                {portals
                  .filter((portal, index) => index % 6 === 0)
                  .map(() => (
                    <BlurView
                      intentisty={100}
                      style={[
                        {
                          height: TILE_HEIGHT * 1.4 * 3 - 10,
                          width: width * 0.8,
                          zIndex: -100,
                          shadowOpacity: 0.5,
                          shadowOffset: { width: 0, height: 0 },
                          shadowRadius: 5,
                          borderRadius: 8,
                          borderWidth: Platform.OS === "android" ? 2 : 0,
                          borderColor: "rgba(255,255,255,0.2)",
                          marginBottom: 10
                        },
                        Platform.OS === "web"
                          ? { boxShadow: "0px 0px 5px rgba(0,0,0,0.7)" }
                          : {}
                      ]}
                    ></BlurView>
                  ))}
              </View>
            }
            renderItem={(item, index) =>
              renderTile({ item, index, disabled: true })
            }
            renderBottomView={
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  height: 100,
                  zIndex: 100,
                  marginTop: 60
                }}
              ></View>
            }
            onDataChange={(data) => {
              Database.addTask("customizeHomeScreen", "done");
              const portalKeys = data.map((portal) => portal.navName);
              if (portalKeys.length > 0) Rex.orderChanged(data);
              checkRefresh();
            }}
          />
        )}
      </SafeAreaView>
    );

  return (
    <SafeAreaView>
      <ScrollView
        horizontal={Platform.OS !== "web"}
        pagingEnabled
        showsHorizontalScrollIndicator={false}
        onMomentumScrollEnd={(e) => {
          setCurrentPage(Math.round(e.nativeEvent.contentOffset.x / width));
        }}
      >
        {getViews().map((view) => {
          return (
            <FlatList
              scrollEnabled={Platform.OS === "web"}
              data={view.moduleList}
              renderItem={renderTile}
              contentContainerStyle={styles.grid}
              keyExtractor={(item, index) => item + index}
              numColumns={2}
              columnWrapperStyle={{ justifyContent: "space-between" }}
            />
          );
        })}
      </ScrollView>
      {numberOfPages > 1 && Platform.OS === "web" && (
        <View
          style={{
            width: "100%",
            height: height * 0.05,
            position: "absolute",
            top: height * 0.7,
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <AntDesign
            name="down"
            size={height * 0.05}
            color="rgba(255,255,255,0.4)"
          />
        </View>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  /* Style for the portals for the home screen */
  tile: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: TILE_WIDTH,
    height: TILE_HEIGHT,
    paddingBottom: 0.03 * height,
    margin: 0.05 * width,
    borderRadius: 0.179 * width,
    borderWidth: 0,
    borderColor: "white",
    shadowOpacity: 0.3,
    shadowRadius: 7,
    shadowOffset: { width: 0, height: 0 },
    elevation: 10
  },

  /* Style for the section of the portals that holds the text */
  tileTextSection: {
    marginTop: -0.012 * height,
    marginBottom: 0.012 * height,
    width: 1.15 * TILE_WIDTH,
    alignSelf: "center"
  },

  /* Styles the grid format of the list view */
  grid: {
    paddingLeft: GRID_PADDING_WIDTH,
    paddingRight: GRID_PADDING_WIDTH,
    flexDirection: "column",
    height,
    width
  }
});

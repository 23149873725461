import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableHighlight,
  SectionList,
  ActivityIndicator,
  TouchableOpacity
} from "react-native";
import { AntDesign } from "@expo/vector-icons";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import NavBar from "src/components/navBar";
import SearchBar from "src/components/searchBar";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import Moment from "moment";
import Util from "src/utility";

const { height, width } = Glob.get("dimensions");
const ICON_SIZE = Math.min(0.07 * width, Glob.deviceIsTablet() ? 42 : 26);

// // Linear with easing
// const CustomLayoutLinear = {
//   duration: 200,
//   create: {
//     type: LayoutAnimation.Types.linear,
//     property: LayoutAnimation.Properties.opacity
//   },
//   update: {
//     type: LayoutAnimation.Types.curveEaseInEaseOut
//   }
// };

// // Spring
// const CustomLayoutSpring = {
//   duration: 400,
//   create: {
//     type: LayoutAnimation.Types.spring,
//     property: LayoutAnimation.Properties.scaleXY,
//     springDamping: 0.7
//   },
//   update: {
//     type: LayoutAnimation.Types.spring,
//     springDamping: 0.7
//   }
// };

export default function Events({
  route,
  navigation,
  navName,
  portalContentOverride
}) {
  const { params } = route || {};
  const [searching, setSearching] = useState(false);
  const [zeroEvents, setZeroEvents] = useState(false);
  const [originalDataRows, setOriginalDataRows] = useState(null);
  const [dataRows, setDataRows] = useState(null);
  const [errorFetchingEvents, setErrorFetchingEvents] = useState(false);
  const [screenTitle, setScreenTitle] = useState("Events");
  const [allEvents, setAllEvents] = useState(null);
  const [googleCalendar, setGoogleCalendar] = useState(null);

  const getAllEventsAndHeaders = () => {
    // Note: This wasn't being logged between Dec 6, 2022 – Dec ~22, 2023 (https://github.com/seabirdapps/aa-dev-app/commit/226f22ad58058b8be0beb85080a5dbbfb58b89ef)
    Analytics.logEvent("view_events");
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const portalName = navName || params?.navName;
    Database.getPortalContentNew(portalName).then((portalContentFetched) => {
      const portalContent = portalContentOverride || portalContentFetched;
      const {
        googleCalendarIDs,
        googleCalendarID,
        iCalURL,
        googleSheetID,
        splitUpMultiDayEvents,
        hoursOffset,
        title
      } = portalContent || {};
      setScreenTitle(title || "Events");
      setGoogleCalendar(googleCalendarID || null);
      Database.fetchEvents({
        googleCalendarIDs,
        googleCalendarID,
        iCalURL,
        googleSheetID,
        hoursOffset
      })
        .then((value) => {
          let events = [];

          if (value && value.length > 0) {
            let formattedEvents = [];
            value.forEach((event) => {
              const date = Moment(event.day).format("YYYY-MM-DD");
              // if the event already happened in the past, skip it
              if (Util.getDaysSince0(date) < Util.getDaysSince0()) return;

              const durationInDays = Moment(event.endTime).diff(
                Moment(event.startTime),
                "days"
              );
              let timeText = Moment(event.startTime).format("h:mm a");
              if (durationInDays > 0) timeText = "All day";
              if (durationInDays > 1) {
                if (splitUpMultiDayEvents) {
                  timeText = "All day";
                  const eventsToAdd = [...Array(durationInDays).keys()]
                    .filter((daysToAdd) => daysToAdd !== 0)
                    .map((daysToAdd) => {
                      const newDate = Moment(date)
                        .add(daysToAdd, "days")
                        .format("YYYY-MM-DD");
                      return {
                        ...event,
                        durationInDays,
                        timeText,
                        date: newDate,
                        day: newDate,
                        startTime: newDate,
                        endTime: Moment(newDate)
                          .add(1, "days")
                          .format("YYYY-MM-DD")
                      };
                    });
                  formattedEvents = [...formattedEvents, ...eventsToAdd];
                } else {
                  timeText = `${durationInDays} days`;
                }
              }
              formattedEvents.push({
                ...event,
                date,
                durationInDays,
                timeText
              });
            });

            formattedEvents.sort((a, b) => new Date(a.date) - new Date(b.date));

            const eventsByDate = {};
            const eventHeaders = [];
            formattedEvents.forEach((event) => {
              const friendlyDate = Util.friendlyDate(event.date);
              if (!eventsByDate[friendlyDate]) {
                eventsByDate[friendlyDate] = [event];
                eventHeaders.push(friendlyDate);
              } else {
                eventsByDate[friendlyDate].push(event);
              }
            });

            events = eventHeaders.map((header) => ({
              title: header,
              data: eventsByDate[header].sort(timeSortComparison)
            }));
          }

          const allEventsNew = [];
          events.forEach((dayObj) => allEventsNew.push(...dayObj.data));

          setSearching(false);
          setDataRows(events);
          setOriginalDataRows(events);
          setAllEvents(allEventsNew);
          setZeroEvents(events.length < 1);
        })
        .catch(() => {
          setSearching(false);
          setDataRows([]);
          setOriginalDataRows([]);
          setAllEvents([]);
          setZeroEvents(true);
          setErrorFetchingEvents(true);
        });
    });
  };

  useEffect(getAllEventsAndHeaders, []);

  // Search function called when user searches for events
  const searchEvents = (searchKey) => {
    Analytics.logEvent("touch_events_search", { search_phrase: searchKey }); // TODO/NOTE: i don't think we really want to log it every time someone changes a letter in the search???
    if (searchKey) {
      const newDataRows = [
        {
          title: "Search Results:",
          data: allEvents.filter((event) => eventIsRelevant(event, searchKey))
        }
      ];
      setSearching(true);
      setDataRows(newDataRows);
      setZeroEvents(newDataRows.length === 0);
    } else {
      setSearching(false);
      setDataRows(originalDataRows);
    }
  };

  // helper function used in componentWillMount
  // sorts times within a particular day
  const timeSortComparison = (a, b) => {
    if (a.startTime < b.startTime) return -1;
    if (a.startTime > b.startTime) return 1;
    return 0;
  };

  // Helper function for events search, returns true if event is good (matches search) and false otherwise
  const eventIsRelevant = (eventInfoBlob, searchKey) => {
    // If the event already happened before today, skip it
    if (Util.getDaysSince0(eventInfoBlob.day) < Util.getDaysSince0()) {
      return false;
    }

    // Add event if event title contains search string
    if (
      eventInfoBlob.event &&
      eventInfoBlob.event.toUpperCase().includes(searchKey.toUpperCase())
    ) {
      return true;
    }
    // Add event if event location includes search string
    if (
      eventInfoBlob.location &&
      eventInfoBlob.location.toUpperCase().includes(searchKey.toUpperCase())
    ) {
      return true;
    }
    // Don't add event if it didn't match anything
    return false;
  };

  const renderRowDay = (day) => {
    if (searching)
      return (
        <Text style={styles.listSectionTimeText}>
          {Moment(day).format("MMM D")}
        </Text>
      );
    return null;
  };

  // Form dates to distinguish from events
  const renderRow = ({ item }) => {
    return (
      <TouchableHighlight
        key={item.key}
        underlayColor="transparent"
        onPress={() => navigation.push("eventDetail", { event: item })}
        style={{
          borderBottomWidth: 1,
          borderBottomColor: Glob.get("extremely light gray")
        }}
      >
        <View style={styles.listSection}>
          <View style={styles.listSectionTime}>
            {renderRowDay(item.day)}
            <Text style={styles.listSectionTimeText}>{item.timeText}</Text>
          </View>
          <View style={styles.listSectionInfo}>
            <Text style={styles.listSectionTitle}>
              {item.event?.truncate(150) || "(No title)"}
            </Text>
            {!!item.location && (
              <Text style={styles.listSectionText}>{item.location}</Text>
            )}
          </View>
        </View>
      </TouchableHighlight>
    );
  };

  const renderSectionHeader = ({ section: { title } }) => {
    return (
      <View style={styles.listHeader}>
        <Text style={styles.listHeaderText}>{title}</Text>
      </View>
    );
  };

  const CalendarViewButton = googleCalendar ? (
    <TouchableOpacity
      style={{
        width: "100%",
        height: "100%",
        alignItems: "flex-end",
        justifyContent: "center",
        paddingRight: 10
      }}
      activeOpacity={0.6}
      onPress={() => {
        Analytics.logEvent("touch_events_calendarView");
        navigation.push("webNav", {
          title: screenTitle,
          url: `https://calendar.google.com/calendar/u/0/embed?showTitle=0&&showPrint=0&showCalendars=0&mode=month&bgcolor=%23FFFFFF&src=${googleCalendar}`
        });
      }}
    >
      <AntDesign name="calendar" size={ICON_SIZE} color="white" />
    </TouchableOpacity>
  ) : null;

  return (
    <View style={styles.pageContent}>
      <NavBar
        navigation={navigation}
        text={screenTitle}
        RightButton={CalendarViewButton}
      />
      <View
        style={[
          styles.sectionHeader,
          { backgroundColor: Rex.getConfig()?.colors?.background }
        ]}
      >
        <SearchBar placeholder="Search events" onChangeText={searchEvents} />
        <Text
          style={{
            marginTop: 10,
            marginBottom: 5,
            color: "#fff",
            fontWeight: "bold",
            fontSize: 20
          }}
        >
          Tap any event to view details.
        </Text>
      </View>
      <View style={styles.mainContent}>
        {zeroEvents && (
          <Text style={styles.noEventsText}>No events found!</Text>
        )}
        {errorFetchingEvents && (
          <Text style={[styles.invisible, zeroEvents && styles.noEventsText]}>
            We're having trouble connecting to the calendar, sorry 😕
          </Text>
        )}
        {originalDataRows === null && (
          <View style={{ width: "100%", alignItems: "center" }}>
            <ActivityIndicator size="large" />
          </View>
        )}
        {dataRows && (
          <SectionList
            style={{ width: "100%" }}
            showsVerticalScrollIndicator={false}
            sections={dataRows}
            keyExtractor={(item, index) => item + index}
            renderItem={renderRow}
            renderSectionHeader={renderSectionHeader}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  /* Style for the section that will hold the sorting function */
  sectionHeader: {
    width,
    flexDirection: "column",
    alignItems: "center",
    opacity: 0.5,
    paddingTop: 10
  },

  pageContent: {
    flex: 1,
    backgroundColor: Glob.get("light gray"),
    alignItems: "center",
    width: "100%"
  },

  /* Style for the main section that will hold all the of the content */
  mainContent: {
    width,
    backgroundColor: "white",
    flexWrap: "wrap",
    justifyContent: "center",
    flexDirection: "column",
    flex: 9
  },

  /* Styles for the list headers above the events */
  listHeader: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 0.013 * width,
    backgroundColor: Glob.get("extremely light gray"),
    justifyContent: "center"
  },

  /* Style for the list headers' text */
  listHeaderText: {
    fontSize: 16,
    textAlign: "center",
    paddingLeft: 0.027 * width,
    color: "#444",
    fontWeight: "bold"
  },

  /* Style for the list section */
  listSection: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    backgroundColor: "white",
    paddingLeft: 0.027 * width
  },

  /* Style for the list section time */
  listSectionTime: {
    flexDirection: "column",
    justifyContent: "center",
    marginRight: 0.013 * width
  },

  /* Style for the list section time's text */
  listSectionTimeText: {
    width: 0.2 * width,
    fontSize: 18,
    color: Glob.get("gray"),
    fontWeight: "400"
  },

  /* Style for the list section information */
  listSectionInfo: {
    flexDirection: "column",
    justifyContent: "center",
    minHeight: 50,
    paddingVertical: 3,
    paddingRight: 5,
    flex: 1
  },

  /* Style for the list section title */
  listSectionTitle: {
    fontSize: 16,
    color: Glob.get("gray"),
    flexWrap: "wrap"
  },

  /* Style for the list section text */
  listSectionText: {
    width: 0.741 * width,
    fontSize: 0.018 * height,
    height: 0.03 * height,
    color: "rgba(93, 93, 93, 0.7)",
    fontStyle: "italic"
  },

  /* Styles for the "No events found!" text */
  noEventsText: {
    fontStyle: "italic",
    fontSize: 0.03 * height,
    color: "darkgray",
    textAlign: "center",
    marginTop: 0.045 * height,
    opacity: 1,
    width: "100%"
  },

  invisible: {
    fontSize: 1,
    marginTop: -5,
    opacity: 0,
    color: "white"
  }
});

import React, { useState, useRef, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ImageBackground,
  TouchableOpacity,
  TextInput,
  ScrollView,
  Platform,
  ActivityIndicator
} from "react-native";
import School from "school/school";
import Rex from "src/globalState";
import Glob from "src/globalConstants";
import Firebase from "src/backend/firebase";
import Database from "src/backend/database";
import Analytics from "src/backend/analytics";
import Button from "src/components/Button";
import Util from "src/utility";

const EMAIL_IMAGE = require("resources/images/login/email.png");

const { height, width } = Glob.get("dimensions");

export default function ForgotPassword({ navigation, route }) {
  const { params: { prefilledEmail } = {} } = route || {};
  const RefEmail = useRef(null);
  const [email, setEmail] = useState(prefilledEmail || "");
  const [logoURL, setLogoURL] = useState(null);
  const [sendingPasswordResetEmail, setSendingPasswordResetEmail] = useState(
    false
  );
  const [sentPasswordResetEmail, setSentPasswordResetEmail] = useState(false);

  useEffect(() => {
    Analytics.logEvent("view_login");
    Firebase.getMediaURLAsync("logo.png").then(setLogoURL);
  }, []);

  const sendPasswordResetEmail = () => {
    setSendingPasswordResetEmail(true);
    Analytics.logEvent("touch_login_passwordResetViaEmail", { email });
    Database.sendPasswordResetEmail(email)
      .then(() => {
        setSentPasswordResetEmail(true);
        setSendingPasswordResetEmail(false);
        Util.alert(
          `Email sent to ${email}`,
          "Make sure to check your spam folder too."
        );
      })
      .catch((error) => {
        setSendingPasswordResetEmail(false);
        console.log("error");
        console.log(error.code);
        switch (error.code) {
          case "auth/invalid-email":
            Util.alert(
              `Invalid Email`,
              `It looks like "${email}" is an invalid email.`
            );
            break;
          case "auth/user-not-found":
            Util.alert(
              `No Account Found`,
              `It looks like we don’t yet have an account registered for "${email}", which means you should be able to go back and create a new account using that email.`
            );
            break;
          default:
            Util.alert(`Error: ${error}`);
            break;
        }
      });
  };

  const backgroundColor =
    Rex.getConfig()?.colors?.background || Glob.get("onespotColor");

  return (
    <View style={{ backgroundColor }}>
      <ImageBackground
        source={School.get("background image")}
        style={styles.backgroundImage}
      >
        <View style={{ flex: 1 }}>
          <ScrollView scrollEnabled={false}>
            <View style={styles.mainView}>
              <Image source={{ uri: logoURL }} style={styles.logo} />
              <View style={styles.field}>
                <Image source={EMAIL_IMAGE} style={styles.fieldImage} />
                <TextInput
                  ref={RefEmail}
                  style={styles.credentials}
                  onChangeText={setEmail}
                  placeholder="Email"
                  placeholderTextColor="rgba(255, 255, 255, 0.5)"
                  selectionColor="white"
                  keyboardType="email-address"
                  autoCorrect={false}
                  autoCapitalize="none"
                  value={email}
                  returnKeyType="send"
                  onSubmitEditing={sendPasswordResetEmail}
                />
              </View>
              <View style={styles.divider} />
              <View style={{ marginTop: 0.022 * height }}>
                {sendingPasswordResetEmail ? (
                  <ActivityIndicator color="white" size="large" />
                ) : (
                  <TouchableOpacity
                    activeOpacity={0.7}
                    style={styles.roundedRectangle}
                    disabled={!email}
                    onPress={sendPasswordResetEmail}
                  >
                    <Text
                      style={[styles.loginText, { color: backgroundColor }]}
                    >
                      Email me
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
              <View style={{ marginTop: 20, marginHorizontal: 15 }}>
                <Text
                  style={{
                    color: "white",
                    fontSize: 18,
                    textAlign: "center"
                  }}
                >
                  {sentPasswordResetEmail
                    ? "Didn't receive an email?\nWait a couple minutes, check your spam folder, and then text us:"
                    : "We'll email you a link to reset your password."}
                </Text>
                {sentPasswordResetEmail && (
                  <Button
                    text="Text us"
                    type="sms"
                    options={{
                      smsRecipient: "4407817739",
                      smsContent: `Hi app team! Could you please send me a reset-password email? My email address is "${email}". Thanks!`
                    }}
                    style={{ backgroundColor: "rgba(0,0,0,0.3)" }}
                  />
                )}
              </View>

              <TouchableOpacity
                activeOpacity={0.7}
                onPress={() => navigation.goBack()}
                style={{
                  position: "absolute",
                  bottom: 0,
                  alignItems: "center",
                  flexDirection: "column",
                  width,
                  height: 0.12 * height,
                  justifyContent:
                    Platform.OS === "android" ? "flex-start" : "center",
                  paddingTop: Platform.OS === "android" ? 5 : 0
                }}
              >
                <View>
                  <Text
                    style={{
                      color: "white",
                      fontSize: 0.024 * height,
                      textAlign: "center"
                    }}
                  >
                    All done here?
                  </Text>
                  <Text style={styles.signUpButton}>Back to Log in!</Text>
                </View>
              </TouchableOpacity>
            </View>
          </ScrollView>
        </View>
      </ImageBackground>
    </View>
  );
}

const styles = StyleSheet.create({
  backgroundImage: {
    height,
    width: "100%",
    flexDirection: "column",
    alignItems: "center"
  },
  mainView: {
    flexDirection: "column",
    alignItems: "center",
    height,
    width
  },
  logo: {
    width: Glob.deviceIsTablet() ? height * 0.2 : width * 0.4,
    height: Glob.deviceIsTablet() ? height * 0.2 : width * 0.4,
    resizeMode: "contain",
    marginTop: 0.2 * height,
    marginBottom: 30
  },
  field: {
    flexDirection: "row",
    marginTop: 0.03 * height,
    alignItems: "center"
  },
  fieldImage: {
    height: 24,
    width: 0.06 * width,
    resizeMode: "contain",
    marginLeft: 0.1 * width
  },
  credentials: {
    color: "white",
    width: 0.719 * width,
    textAlign: "left",
    height: 0.059 * height,
    paddingLeft: 0.027 * width,
    fontWeight: "400"
  },
  divider: {
    height: 1,
    width: 0.719 * width,
    backgroundColor: "rgba(255, 255, 255, 0.5)"
  },
  roundedRectangle: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    height: 0.063 * height,
    width: 0.719 * width,
    borderRadius: 0.075 * height,
    borderWidth: 0
  },
  loginText: {
    fontSize: 0.035 * height,
    fontWeight: "400",
    textAlign: "center"
  },
  signUpButton: {
    color: "white",
    fontSize: 0.027 * height,
    fontWeight: "bold",
    textAlign: "center"
  }
});

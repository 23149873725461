import React from "react";
import {
  TouchableOpacity,
  StyleSheet,
  Text,
  View,
  Platform,
  Image
} from "react-native";
import Glob from "src/globalConstants";
import Rex from "src/globalState";
import School from "school/school";
import Icon from "src/components/Icon";
import Util from "../utility";

const { height, width } = Glob.get("dimensions");

const MODULE_TEXT_PADDING = -0.002 * height;

export default function Tile({
  navigation,
  navName,
  icon,
  iconURL,
  portalType,
  txtName,
  restrictedToFeedSubscribers,
  allowedAccountTypes,
  disabled,
  isEditing,
  globalConfig,
  onUpdatePortal,
  onPress,
  condense,
  darkStyle,
  toggleCustomizingHomeScreen
}) {
  const config = globalConfig || Rex.getConfig();
  const isLargeSquareDesign = globalConfig?.portalShape === "square";
  const largeMultiplier = isLargeSquareDesign ? 1.5 : 1;
  const tileWidth = Glob.deviceIsTablet()
    ? largeMultiplier * 0.8 * 0.278 * width
    : largeMultiplier * 0.278 * width;
  const tileHeight = Glob.deviceIsiPhoneX()
    ? largeMultiplier * 0.8 * 0.154 * height
    : largeMultiplier * 0.154 * height;

  const navigate = (screenName) => {
    // If public web pages are enabled, update the url whenever you navigate pages
    if (Platform.OS === "web" && Rex.getConfig()?.publicPagesEnabled) {
      Util.setURLQueryString(
        `?app=${School.getDatabaseAppID()}&screen=${navName}`
      );
    }
    navigation.push(screenName, {
      navName,
      icon,
      portalType,
      txtName,
      restrictedToFeedSubscribers,
      allowedAccountTypes,
      onUpdatePortal
    });
  };

  let fontSize = 0.032 * width;
  if (Glob.deviceIsTablet()) fontSize *= 0.7;

  const tileStyle = {
    flexDirection: "column",
    justifyContent: isLargeSquareDesign ? "flex-start" : "center",
    alignItems: "center",
    width: tileWidth,
    height: tileHeight,
    paddingBottom: 0.03 * height,
    margin: isLargeSquareDesign ? 0 : 0.05 * width,
    borderRadius: isLargeSquareDesign ? 0.05 * width : 0.179 * width,
    borderWidth: 0,
    borderColor: "white",
    shadowOpacity: 0.3,
    shadowRadius: 7,
    shadowOffset: { width: 0, height: 0 },
    elevation: 10,
    backgroundColor: config?.colors?.portal
  };

  const textSectionStyle = {
    marginTop: -0.012 * height,
    marginBottom: 0.012 * height,
    width: 1.15 * tileWidth,
    alignSelf: "center"
  };

  let topMarginObj = {
    marginTop: Glob.deviceIsiPhoneX() ? 0.067 * height : 0.067 * height * 0.5
  };
  if (isEditing || condense) {
    if (isLargeSquareDesign)
      topMarginObj = {
        marginTop: 0.067 * height * 0.5
      };
    else topMarginObj = {};
  }

  return (
    <View>
      <TouchableOpacity
        onLongPress={() => {
          if (!isEditing) toggleCustomizingHomeScreen(true);
        }}
        style={[
          tileStyle,
          topMarginObj,
          Platform.OS === "web"
            ? { boxShadow: "0px 0px 7px rgba(0,0,0,0.3)" }
            : {},
          darkStyle
            ? {
                backgroundColor: "black",
                justifyContent: "center",
                alignItems: "center",
                opacity: 0.7
              }
            : {}
        ]}
        activeOpacity={0.7}
        disabled={disabled}
        onPress={() => {
          if (onPress) onPress();
          // e.g. "food"
          else if (portalType === "native") navigate(navName);
          // e.g. "webNav" or "dynamic"
          else navigate(portalType);
        }}
      >
        <View
          style={{
            flex: 3,
            justifyContent: "center"
          }}
        >
          <View style={styles.iconContainer}>
            <Icon
              icon={icon}
              iconURL={iconURL}
              color={darkStyle ? "white" : config?.colors?.background}
              size={Math.min(0.12 * width, 85)}
            />
          </View>
        </View>
        {isLargeSquareDesign && (
          <View
            style={{
              height: 40,
              width: tileWidth * 0.9,
              justifyContent: "flex-end"
            }}
          >
            <Text
              style={{
                color: darkStyle ? "white" : config?.colors?.background,
                fontWeight: darkStyle ? "bold" : "500",
                textAlign: "center",
                flexGrow: 1,
                fontSize
              }}
            >
              {txtName.toUpperCase().truncate(30)}
            </Text>
          </View>
        )}
      </TouchableOpacity>
      {!isLargeSquareDesign && (
        <View style={textSectionStyle}>
          <Text
            style={{
              color: "white",
              opacity: darkStyle ? 0.8 : 1,
              paddingTop: MODULE_TEXT_PADDING,
              fontWeight: darkStyle ? "bold" : "500",
              textAlign: "center",
              fontSize
            }}
          >
            {txtName.toUpperCase().truncate(30)}
          </Text>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  iconContainer: {
    top: 0.015 * height,
    opacity: 1,
    justifyContent: "center",
    alignItems: "center"
  }
});
